/* 509 롯데슈퍼(~1/30) */
const superData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2025. 1. 23(목) ~ 2025. 1. 30(목) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이

    롯데슈퍼
    설레는 설날, 장보기 대전!

    롯데/신한/삼성카드 30%
    한우 국거리/불고기(1등급)(각 100g/냉장/국내산 한우고기) ※페이지 하단 카드할인 세부내용 참고

    L.POINT 40%
    국내산 돼지고기 삼겹살/목심(각 100g/냉장/국내산) 각2,394원 ※조기 품절될 수 있습니다

    L.POINT 30%
    수입 냉동 LA갈비(각 1kg/냉동/수입소고기) ※조기 품절될 수 있습니다

    1/23(목), 1/24(금), 1/25(토)
    L.POINT 35% -> 롯데/신한/삼성카드 15% 추가할인
    1등급 한우 등심(100g/냉장/국내산 한우고기) ※기간 외 L.POINT 회원 35%

    무항생제 판계란(30입/대란/국산) 6,990원 ※조기 품절될 수 있습니다

    상생 사과(4~7입/봉/국내산) 12,990원

    세계맥주 20종(상품별 용량 상이) 4캔 구매시 8,400원
    ※묶음 구매시 캔당 100원 추가할인 ※1캔 구매시 각 3,000원

    L.POINT 최대 30%
    호주산 청정와규 전품목(각 100g/냉장/호주산 소고기) ※윗등심/구이용/불고기용 외 다른품목 20% 할인 적용
  
    CJ 비비고 왕교자만두(350g×3) 8,990원

    2개 이상 10%
    농심 봉지라면 전품목(상품별 상이) 2개 이상 구매시 1개당 각1,332~6,912원
    ※신라면/올리브짜파게티/얼큰한너구리 外 24종 ※1개 구매시 1,480~7,680원

    CJ 백설 고기양념 4종(각 290g) 2개 구매시 5,000원 ※1개 구매시 각2,980원
    ※돼지불고기/돼지갈비/소불고기/소갈비

    대림 게맛살(150g)/동원 싱싱맛살(500g) 1,190/3,690원

    가성비 스낵 균일가 12종(상품별 용량 상이) 각990원 ※맛동산/허니버터칩 등

    핸드캐리형 선물세트 추천!
    충주사과 29,900원 ▶ 사과 12~14입(3.0kg 내외), 국산
    ※상품권 증정 제외 ※택배 불가

    L.POINT 2,900원 할인
    동서 맥심 20호 세트 L.POINT할인가 27,200원 
    ▶ 맥심 모카 골드 마일드 믹스 12g×200입
    ※상품권 증정 제외
    
    카드할인 30%
    씨제이 특별한선택 L-2호 카드할인가 40,530원 ▶ 카놀라유 500ml×2, 스팸 200g×6, 요리올리고당 700g 外

    2025 롯데슈퍼 물가잡기 캠페인
    매주 꼭 필요한 상품을 THE HOT하게 제안합니다.

    이번주 핫프라이스

    농활할인 20% -> 롯데/신한/삼성카드 500원 할인
    데친 고사리/깐 도라지(각 250g/300g, 팩, 국산) 각7,980원
    ※기간 : 1/25(토)~1/28(화)
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 37% -> 롯데/신한/삼성카드 13% 추가할인
    국내산 돼지 갈비(700g/냉장/국내산) 9,900원
    ※기간 : 1/23(목)~1/26(일) ※기간 외 L.POINT 37%만 적용
    ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/삼성카드 1,000원 할인
    목우촌 9단 살코기햄(1kg) 9,990원
    ※페이지 하단 카드할인 세부내용 참고

    이달의 핫PB 

    3,000원 할인
    요리하다 LA식 꽃갈비(600g) 14,900원 ※운영점에 한함

    1,000원 할인
    요리하다 동그랑땡(425g×2) 6,990원

    오늘좋은 볶음참깨(500g) 6,990원

    오늘좋은 참기름(450ml) 5,990원

    공구핫딜

    공구핫딜 고래사 꼬치어묵 10+2(526g) 6,940원

    공구핫딜 샤워메이트 플라워퍼퓸 바디워시(1,200ml, 옐로우) 4,900원

    공구핫딜 명진 고무장갑 3켤레 기획+속장갑 증정(각 3켤레, 중/대) 각5,000원

    ※정상가란? 2024년 1월 2일(목) ~ 1월 22일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    </div>
  `,
  `
  <div>
    제수용 과일 3종(사과/배/감, 용량 상이, 국산) 15,990/25,990/9,990원

    L.POINT 1,000원
    스위텔 토마토(800g/팩/국산) 8,990원

    농활할인 20%
    제주 타이벡 감귤/한라봉(각 2kg/박스/국산) 14,960/19,920원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    농활할인 20%
    대파(700g/봉/국산) 2,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    농활할인 20%
    GAP 친환경 새송이 버섯(300g/봉/국산)
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    농활할인 20%
    밤(800g/봉/국산) 6,320원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    L.POINT 50%
    창녕 양파품은 한우 불고기(700g/냉장/원산지 별도표기) 16,900원

    L.POINT 45%
    훈제오리 슬라이스(400g/냉장/원산지 별도표기) 9,790원

    L.POINT 3,000원
    항공직송 동원 생연어(320g/냉장/노르웨이산) 16,900원

    L.POINT 40%
    궁 쇠고기 육포 오리지널/골든올리브(각 100g/원산지 별도표기) 8,940원

    L.POINT 2,500원 -> 롯데/신한/삼성카드 1,500원 추가할인
    동원 데친 문어(200g/팩/원산지 별도표기) 6,990원 ※페이지 하단 카드할인 세부내용 참고
  
    하나사면 하나 더 1+1

    1+1
    하림 통가슴살 치킨너겟(500g)/팝콘치킨(650g) 각10,990원 ※교차구매 가능

    1+1
    동원 진국 사골곰탕(500g) 1,980원

    1+1
    떠먹는 요구르트 16종(상품별 상이) 각3,780~6,090원
    ※빙그레 7종/다논 6종/남양 3종
    ※브랜드별 동일 단량 상품 교차구매 가능

    1+1
    CJ 백설 포도씨유/CJ 백설 카놀라유(각 900ml) 9,500/16,000원 ※교차구매 불가

    1+1
    CJ 백설 고소한맛 진한참기름(320ml) 10,900원

    1+1
    동원 참치액 3종(각 500g) 각7,980원 ※진/순/해물 ※교차구매 가능

    1+1
    서울 치즈 2종(각 180g) 각7,590원
    ※고단백/짜지않은치즈 ※교차구매 가능

    1+1
    정식품 17곡 한끼두유/뼈에좋은 칼슘두유(콩깨쌀)(각 190ml×16) 각20,000원 ※교차구매 가능
    
    1+1
    목우촌 주부9단 육즙팡팡 프랑크(350g) 각8,990원

    1+1
    제주우유 무항생제 우유(750ml) 4,990원

    1+1
    롯데 잔치집 식혜(1.5L) 3,480원

    1+1
    비타파워 20입 선물세트(100ml×20입) 12,900원

    1+1
    박준 뷰티랩 염색약(각 120g) 각11,900원 ※흑갈색/자연갈색 ※교차구매 가능

    1+1
    페리오 뉴후레쉬알파치약(185g×4) 10,900원

    우정본가 국내산 떡국떡/쫄깃한 쌀 떡국떡(각 1kg) 5,990/2,990원

    CJ 비비고 도톰동그랑땡,도톰 해물완자/한입떡갈비(각 340g/320g) 2개 구매시 9,900원 ※교차구매 가능
    ※1개 구매시 각5,990원

    풀무원 철판 오징어부추전/바삭감자채전(각 300g) 2개 구매시 9,900원 
    ※1개 구매시 각5,990원

    2개 이상 50%
    삼립 육즙가득 스테이크/미트볼(각 150g) 2개 이상 구매시 1개당 각1,995원 
    ※1개 구매시 각3,990원 ※교차구매 가능

    CJ 백설유 콩기름(500ml)/맛술 2종(생강/로즈마리, 각 500ml)/올리고당(700g) 2개 구매시 5,000원
    ※1개 구매시 각2,980원

    최대1,810원 할인
    오뚜기 옛날 자른당면(500g/300g) 각6,480/3,980원

    동원 비엔나 소세지기획(300g×2) 6,480원

    L.POINT 1,000원
    광천 구운 김가루(50g/원산지 별도표기) 3,990원

    '캐치 티니핑 콜라보 한정판'빈츠 스트로베리/몽쉘 딸기 外 7종(상품별 상이) 1,440~7,980원 ※점포별 운영상품 상이
  
    2개 이상 50%
    자임 스테비아 유자차/레몬차/레몬즙/생강즙(상품별 상이) 2개 이상 구매시 1개당 각1,995원 ※교차구매 가능
    ※1개 구매시 각3,990원

    2개 이상 50%
    수퍼테크 액체세제 리필(각 2.6L, 일반/드럼) 2개 이상 구매시 1개당 각5,950원 ※1개 구매시 각11,900원 ※교차구매 가능

    롯데/신한/삼성카드 1,000원 할인
    잘풀리는집 대용량 키친타월(150매×12롤) 7,900원 ※페이지 하단 카드할인 세부내용 참고

    제수용품 모음전

    L.POINT 2,000원
    백두산맥 기운을 받은 황태포(60g 내외/1미) 4,990원 ※원산지 별도표기

    L.POINT 20%
    간편 수제 명태살/대구살(각 500g/냉동/원산지 별도표기) 7,920/14,320원
    
    해표 식용유(1.7L) 5,980원
    
    오뚜기 부침/튀김가루(각 1kg) 각2,980원
    
    백화수복 골드(700ml/1,800ml) 5,200/10,900원
    
    오늘 뭐먹지? ※델리 운영점에 한함

    모둠전(100g/원산지 별도표기) 3,490원

    녹두빈대떡(3장/원산지 별도표기) 9,990원

    하늘청 수정과/식혜(원산지 별도표기) 각6,490원

    로스트 오븐치킨(팩/원산지 별도표기) 12,990원

    경양식 함박세트(5입/원산지 별도표기) 7,990원

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
];

/* 109 롯데슈퍼(~2/5) */
const superNewData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2025. 1. 31(금) ~ 2025. 2. 5(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이

    롯데슈퍼 맛 좋은 신선과 간편식 추천!

    명절 끝! 집밥 솔루션

    L.POINT 2,000원 -> 롯데/신한/삼성카드 1,000원 추가할인
    한판딸기/상생딸기(각 500g/팩/국산) ※페이지 하단 카드할인 세부내용 참고

    감숙왕 바나나(1.3kg 내외/송이/필리핀산) 3,990원

    L.POINT 최대 5,000원 할인 
    호주산 와규 냉동 구이류 3종(용량 품목별 상이/냉동/호주산 소고기)

    L.POINT 40%
    체리부로 닭가슴살 모음전(각 100g/냉장/원산지 별도표기) 1,668원

    롯데/신한/삼성카드 20%
    한우 간편 구이류(각 100g/냉장/국내산 한우고기)
    ※등심/안심/채끝/부채살/치마살/업진살
    ※페이지 하단 카드할인 세부내용 참고

    풀무원 우동/칼국수/수제비 8종(상품별 상이) 2개 구매시 3,990원 
    ※1개 구매시 각 2,990원 ※교차구매 가능
    ※풀무원 가케우동 2인 407.6g 外 7종

    롯데 의성마늘비엔나(160g×2) 4,490원

    1+1
    씨제이 햇반 곤약밥 2종(각 150g×3입) 각9,480원 ※교차구매 가능

    롯데/신한/삼성카드 1,500원 할인
    하림 용가리치킨+치킨너겟(300g+300g) 6,490원
    ※페이지 하단 카드할인 세부내용 참고

    1+1
    골드셀렉션 쿠키 2종(각 90g, 버터/초콜릿) 각1,980원 ※교차구매 가능

    2025 롯데슈퍼 물가잡기 캠페인 매주 꼭 필요한 상품을 The HOT하게 제안합니다

    이번주 핫프라이스

    L.POINT 50% 
    요리하다 흑돼지 제육볶음(800g/냉장/원산지 별도표기) 9,900원

    롯데/신한/삼성카드 1,000원 할인
    대파(700g/봉/국산) 1,990원 ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/삼성카드 1,000원 할인
    크리넥스3겹순수소프트(30m×30롤) 15,700원
    ※페이지 하단 카드할인 세부내용 참고

    이번주 핫PB

    요리하다 소고기라면(5개입) 2,990원

    1,000원 할인
    요리하다 얼큰한 김치우동 2인(432g) 2,990원

    1,000원 할인
    오늘좋은 칠리새우(400g) 6,990원

    3개 이상 20%, 2개 이상 구매시 10%
    오늘좋은 밀크/다크 초콜릿(각 100g) 3개 이상 구매시 1개당 각1,600원 
    ※2개 구매시 1개당 각 1,800원
    ※1개 구매시 각2,000원 ※교차구매 가능

    공구핫딜

    공구핫딜 고래사 꼬치어묵 중량UP 기획상품(526g) 6,940원

    케라시스 비듬케어 쿨링 샴푸 2종(각 1,000ml, 가려움완화/딥클린) 각5,990원

    샤워메이트 플라워퍼퓸 바디워시(1,200ml/옐로우) 4,900원

    명진 고무장갑 3켤레(중) 5,000원 ※취급점 한정

    ※정상가란? 2025년 1월 10일(금) ~ 1월 30일(목) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
  `
  <div>
    스테비아 대추방울토마토 전품목(각 500g/팩/국산) 2팩 이상 구매시 1팩당 각2,000원 할인

    제주 한라봉/천혜향/레드향(각 4~7입/봉/국산) 각12,990원

    L.POINT 50% 달구벌 소대창(소금구이)(400g/냉동/원산지 별도표기) 8,950원

    롯데/신한/삼성카드 1,000원 할인
    고구마(1.5kg/박스/국산) 6,990원 ※페이지 하단 카드할인 세부내용 참고

    절단샐러리/쥬스용비트(200g/1kg, 봉, 국산) 각3,990원

    L.POINT 30% 국내산 돼지고기 삼겹살 목심(각 100g/냉장/국내산) 2,793원 
    ※조기 품절될 수 있습니다

    롯데/신한/삼성카드 20%
    한우 국거리/불고기(1등급)(각 100g/냉장/국내산 한우고기)
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 40% 
    ASC 냉동 새우살 2종(각 400g/냉동/원산지 별도표기) 13,140원

    L.POINT 40% 
    봉지굴(260g/냉장/국산) 5,940원

    L.POINT 2,000원 
    손질 오징어(3마리/팩/냉장/원양산) 9,990원

    하나사면 하나 더 1+1

    1+1
    CJ 해찬들 새콤달콤 초고추장(1.05kg) 8,980원

    1+1
    백설 고소함가득 참기름(320ml) 10,900원

    1+1
    떠먹는 요구르트 15종(상품별 상이) 각3,780~5,290원
    ※빙그레 3종/매일 3종/다논 6종/남양 3종
    ※브랜드별 동일상품 교차구매 가능

    1+1
    오뚜기 지역식 국물요리 5종(각 500g) 6,480원 ※교차구매 가능

    1+1
    사세 치킨 가라아게(500g) 9,990원

    1+1
    롯데 칠성/펩시 페트 5종(각 1.25L) 각3,580원 ※교차구매 가능

    1+1
    매일 뼈로가는 칼슘치즈(180g) 7,190원

    1+1
    흑깨소미(560g) 11,900원 ※한정수량

    1+1
    레모나 에스산(각 1.5g×90포) 각21,800원

    1+1
    녹차원 꿀 유자차/레몬생강차(각 1kg) 각9,900원 ※교차구매 가능

    1+1
    웅진 티즐제로 2종(각 1.5L) 각3,680원
    ※피치우롱티/딸기루이보스 ※교차구매 가능

    1+1
    세꼼마 핸드워시(각 250ml, 파우더/레몬) 각6,900원 ※교차구매 가능

    동원 비엔나 소세지기획(300g×2) 6,480원

    화요(41%) 테이스팅잔 세트(375ml×2) 49,900원

    엘라스틴 아미노프로틴 샴수/린스 3종(각 855ml) 각7,990원

    '캐치 티니핑 콜라보 한정판' 빵빠레바 스트로베리(75ml)/월드콘 딸기(160ml)/티코 딸기(510ml) 700/1,200/6,490원

    2+1
    미에로 화이바 스파클링 제로(350ml) 1,900원

    2개 이상 50%
    프릴 맑은 식초용기(각 700ml) 2개 이상 구매시 1개당 각4,750원 ※1개 구매시 각9,500원 ※교차구매 가능

    오늘 뭐 먹지? ※델리 운영점에 한함

    THE 큰치킨(마리/원산지 별도표기) 9,900원

    NEW 갱엿닭강정(중)(팩/원산지 별도표기) 6,990원

    연어새우 한판초밥(12입/원산지 별도표기) 9,990원

    ※정상가란? 2025년 1월 10일(금) ~ 1월 30일(목) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getSuperData = () => {
  const dataArray = [];
  superData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getSuperNewData = () => {
  const dataArray = [];
  superNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L509: {
    title: "스마트전단지-슈퍼509",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202501_5/02",
    contents: getSuperData(),
  },
  L109: {
    title: "스마트전단지-슈퍼109",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202502_1/02",
    contents: getSuperNewData(),
  },
};

export default data;
