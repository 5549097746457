import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2025. 1. 23(목) ~ 2025. 1. 30(목) < 비신선식품 적용기간 : 2025. 1. 16(목) ~ 1. 30(목) >

    롯데마트
    설레는 설날, 장보기 대전!

    수입 찜갈비/호주산 와규 윗등심(각 100g/냉장/원산지 별도표기) L.POINT 최대 40%

    LA갈비(1kg/냉동/원산지 별도표기) L.POINT 10,000원 할인
 
    수산대전 20% -> 롯데/신한/NH농협/삼성카드 20% 추가할인
    봉지굴 전품목
    ※수산대전 : 1/23(목) ~ 1/29(수),카드할인 : 1/23(목) ~ 1/30(목)
    ※봉지굴 전품목 해당
    ※L.POINT회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    한우 국거리/불고기(1등급)ㄴ(각 100g/냉장/국내산 한우고기) L.POINT 30%

    L.POINT 20%
    간편 수제 명태살/대구살/홍메기전(각 500g/냉동/원산지 별도표기) 7,920/14,320/11,920원

    부세(마리/해동/중국산) 4,490원

    도미(마리/해동/국산) 6,990원

    물가안정 참조기(대)(마리/해동/국산) 2,990원

    농활할인 20%
    제주 한라봉(2kg/박스/국산) 18,960원 ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    
    농활할인 20%
    겨울 시금치(단/국산) 3,496원
    ※조기 품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    농활할인 20%
    데친 고사리/깐 도라지(300g/250g, 팩/국산) 각7,992원
    ※기간 : 1/25(토) ~ 1/30(목)
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    우리쌀 떡국떡(100g/원산지 별도표기) 600~1,290원
    ※기간 : 1/27(월) ~ 1/29(수)

    L.POINT 2,000원
    제수용 황태포(60g 내외/75g 내외, 1미/원산지 별도표기) 4,990/5,990원

    제수용 사과(3입/팩/국산) 15,990원

    제수용 배(3입/팩/국산) 25,990원

    제수용 감(5입/봉/국산) 9,990원

    L.POINT 2,000원
    제수용 곶감(8입/팩/국산) 7,990원

    농활할인 20%
    밤(1kg/봉/국산) 8,720원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    2개 이상 최대2,480원 할인
    풀무원 적전류 5종(상품별 상이) 2개 이상 구매시 1개당 각5,000원 ※1개 구매시 각 5,980~7,480원 ※교차구매 가능

    롯데 의성마늘햄 外 햄/맛살/단무지(상품별 상이) 1,290~10,900원

    420원 할인
    오뚜기 부침/튀김가루(각 1kg) 각2,980원

    최대1,200원 할인
    오뚜기 옛날 자른당면(300g/500g) 각3,980/6,480원

    740원 할인
    팔도 비락식혜/비락식혜 제로/수정과(각 1.5L) 각2,980원
    
    별빛청하 변온잔 패키지(295ml×4) 7,960원

    2025 롯데마트 물가잡기 캠페인 
    매주 꼭 필요한 상품을 THE HOT하게 제안합니다

    이번주 핫프라이스

    롯데/신한/NH농협/삼성카드 6,000원 할인
    국내산 돼지 갈비(700g/냉장/국내산) 8,900원
    ※기간 : 1/23(목) ~ 1/27(월)
    ※제주점 제외 ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/NH농협/삼성카드 30% -> 수산대전 20%
    국산 데친문어(100g/냉장/국산) 2,492원
    ※수산대전 : 1/23(목) ~ 1/29(수), 카드할인 : 1/23(목) ~ 1/30(목)
    ※L.POINT회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    2,500원 할인 -> 롯데/신한/NH농협/삼성카드 500원 추가할인
    목우촌 주부9단 살코기햄(1kg) 9,980원
    ※기간 : 1/23(목) ~ 1/30(목)
    ※페이지 하단 카드할인 세부내용 참고

    이달의 핫 PB

    3,000원 할인
    요리하다 LA식 꽃갈비(600g) 14,900원

    1,000원 할인
    요리하다 동태전(300g) 4,990원

    1,000원 할인
    오늘좋은 우리쌀 미니약과(500g) 3,990원

    1,000원 할인
    오늘좋은 식혜(238ml×12입) 5,990원

    공구핫딜

    공구핫딜 고래사 꼬치어묵(526g) 6,940원

    공구핫딜 샤워메이트 플라워퍼퓸 바디워시(1,200ml, 옐로우) 4,900원

    공구핫딜 명진 고무장갑 3켤레 기획+속장갑 증정(각 3켤레, 중/대) 각5,000원

    ※정상가란? 2025년 1월 2일(목) ~ 2025년 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `<div>
    L.POINT 40%
    1등급 브랜드 돼지고기 삼겹살/목심(각 100g/냉장/국내산) 각2,928원
    ※제주점 제외 ※조기 품절될 수 있습니다

    L.POINT 50%
    창녕 양파품은 한우 불고기(700g/냉장/원산지 별도표기) 15,900원

    L.POINT 20,000원
    요리하다 양념 LA 꽃갈비(800g/냉장/원산지 별도표기) 29,900원
    ※조기 품절될 수 있습니다

    L.POINT 3,000원
    훈제오리 슬라이스(500g/냉장/원산지 별도표기) 11,990원

    L.POINT 50%
    국산 데친 홍게(8마리/냉장/국산) 39,950원
    ※기간 : 1/24(금)~1/26(일) ※일부점에 한함

    롯데/신한/NH농협/삼성카드 20% -> 수산대전 20%
    완도 활 전복(왕/특)(마리/냉장/국산) 3,904/2,656원
    ※수산대전 : 1/23(목)~1/29(수)/카드할인 : 1/23(목)~1/30(목)
    ※L.POINT회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    물가안정 갈치/오징어(각 1마리, 해동, 갈치: 국산/오징어 : 원양산) 각2,900원

    L.POINT 20%
    도미회(300g 내외/냉장 /도미 : 국산) 34,800원

    L.POINT 20%
    껍질벗긴 새우(750g/냉동/원산지 별도표기) 19,120원

    L.POINT 30%
    내가만드는 연어(횟감용/구이용)(각 100g/냉장/노르웨이산) 3,990/3,710원
    ※조기 품절될 수 있습니다

    L.POINT 30%
    파타고니아 생연어 필렛 대용량 세트(800g 내외/냉장/연어 : 칠레산) 38,430원

    2개 이상 각500원 할인
    대천 재래, 파래/곱창 식탁김(각 5g×20봉/원산지 별도표기) 2개 이상 구매시 1개당 각6,990/7,990원 ※교차구매 가능
    ※1개 구매시 각7,490/8,490원

    농활할인 20%
    대파(700g/국산) 2,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    GAP 친환경 새송이 버섯(300g×2봉/국산) 4,990원

    페루 애플망고(6입/박스/페루산) 29,900원

    농활할인 20%
    제주 타이벡 감귤(2kg/박스/국산) 14,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    L.POINT 2,000원
    스위텔 토마토(800g/팩/국산) 7,990원

    옛날 두마리 치킨(1팩/국내산 계육) 16,990원

    반값득템초밥(30입/1팩/원산지 별도표기) 14,990원

    L.POINT 20% 해물 양장피(1팩/원산지 별도표기) 15,992원

    한입 쏙 진도 대파 떡갈비(1팩/원산지 별도표기) 7,990원

    모둠전&나물(100g/원산지 별도표기) 각3,900원
    ※기간 : 1/27(월)~1/29(수)
    ※일부품목에 한함 ※반찬 운영점 한함

    하나사면 하나 더 1+1

    1+1
    동원 리얼 크랩스/랍스터(상품별 상이) 각5,980원 ※교차구매 가능

    1+1
    하림 텐더스틱(550g) 11,480원

    1+1
    오뚜기 피자 4종(상품별 규격 상이) 각9,980원
    ※ 콤비네이션/불고기/마르게리타/콘치즈 ※교차구매 가능

    1+1
    빙그레 요플레 4입 5종(상품별 상이) 각3,780원 ※교차구매 가능

    1+1
    하겐다즈 파인트 전품목(상품별 규격 상이) 각15,500원 ※교차구매 가능
    ※하겐다즈 파인트(마카다미아넛) 外 11종

    1+1
    큐원 알룰로스 2종(각 540g) 각9,680원
    ※난소화성말토덱스트린/프리바이오틱스
    ※교차구매 가능

    1+1
    아이배냇 이유식/유아간식 63종(상품별 상이) 각1,500~14,500원 ※동일가격 간 교차구매 가능

    1+1
    델몬트 오렌지/포도/망고(각 1.8L) 각5,480원 ※교차구매 가능

    1+1
    자임 하루하나 유기농 레몬즙/레몬생강즙(각 14입) 각15,900원 ※교차구매 가능

    2개 이상 구매시 더 싸게

    2개 이상 각1,980원 할인
    풀무원 24시 즉석우동 2종 (상품별 상이) 2개 이상 구매시 1개당 각5,000원 
    ※1개 구매시 각6,980원
    ※교차구매 가능

    2개 이상 각500원 할인
    CJ 비비고 동그랑땡/남도 떡갈비 外 4종(상품별 규격 상이) 2개 이상 구매시 1개당 각7,980~8,980원
    ※1개 구매시 각8,480~9,480원 ※교차구매 가능

    2개 이상 각580원 할인
    CJ 육공육 후랑크 2종(255g/275g) 2개 이상 구매시 1개당 각5,000원 ※1개 구매시 각5,580원 ※교차구매 가능

    2개 이상 10%
    농심 봉지라면 전품목 43종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,422~14,040원 
    ※1개 구매시 각1,580~15,600원 ※교차구매 가능 ※농심 농심라면 4개입 外 42종

    2개 이상 10%
    농심 인기 스낵 번들(상품별 상이) 2개 이상 구매시 1개당 각2,682원 ※1개 구매시 각2,980원
    ※새우깡/꿀꽈배기/포테토칩 ※교차구매 가능

    2개 이상 20%
    매일 바이오 2종(각 450g) 2개 이상 구매시 1개당 각2,936원 ※1개 구매시 각3,670원
    ※플레인/플레인저지방 ※교차구매 가능

    2개 이상 50%
    서울 까요까요 4종(상품별 용량 상이) 2개 이상 구매시 1개당 각2,290원 ※1개 구매시 각4,580원
    ※딸기/세븐베리/초코/플레인 ※교차구매 가능

    2개 이상 20%
    요즘 그릭요거트 5종(상품별 용량 상이) 2개 이상 구매시 1개당 각2,800~3,920원 
    ※1개 구매시 각3,500~4,900원 ※교차구매 가능

    2+1
    삼립 호빵 6종(상품별 용량 상이) 5,380~5,880원
    ※3개 구매시 34% 할인 적용 ※교차구매 가능

    2+1
    담터 쌍화차 플러스/생강차 플러스(각 50입) 각13,500원 ※교차구매 가능

    칠갑 우리쌀 떡국/햅쌀떡(1.2kg/1kg) 5,980/7,480원

    20%
    고래사 국탕용어묵(385g) 6,800원

    서울 흰 우유(2.3L) 5,980원

    동원 비피더스 명장기획(125ml×12입) 4,780원

    1,910원 할인
    칠성사이다+칠성사이다/칠성사이다+펩시콜라(각 1.8L×2입) 4,450원

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    롯데 캐치티니핑×스트로베리 기획 과자/베이커리 9종(상품별 규격 상이) 각1,440~7,980원

    오리온 봄 스트로베리 기획 3종(상품별 규격 상이) 각2,400/2,720/4,800원 ※오감자/초코송이/초코파이 

    15% 크라운 참쌀 선과/설병(253g/270g) 각3,672원

    2개 이상 40%
    내추럴박스 레몬즙/레몬생강즙(각 500ml) 2개 이상 구매시 1개당 각5,994원
    ※교차구매 가능 ※1개 구매시 각9,990원

    1+1
    상쾌환 스틱 4종(각 18g×3입) 9,980원 ※교차구매 가능
    ※복숭아맛/망고맛/샤인머스캣맛/사과맛

    동원에프앤비 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉢ "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    1+1
    동원 참치액 3종(각 500g) 각7,980원
    ※해물/진/순 ※교차구매 가능

    1+1
    동원 양반 비빔드밥 6종(상품별 상이) 각4,980원 ※교차구매 가능

    1+1
    동원 양반 상온국물요리 6종(상품별 규격 상이) 각8,980원
    ※수라 왕갈비탕 460g 외 5종 ※교차구매 가능

    동원 양반 완자 2종(각 850g) 8,480/9,980원 ※고기완자/버섯완자

    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정 사조대림 전품목 
    4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " Y "를 확인하세요
    ※결제시 계산대에서 수령

    L.POINT 5,400원
    오뚜기 옛날 참기름(450ml) 7,480원

    2개 이상 10%
    오뚜기 옛날 잡채/매콤 잡채(각 4개입) 2개 이상 구매시 1개당 각5,382원 ※1개 구매시 각5,980원 ※교차구매 가능
      
    1+1
    오뚜기 가쓰오 우동 2인(466g) 6,980원

    오뚜기 XO 교자 3입 3종(상품별 규격 상이) 각8,980원 ※고기/김치/새우&홍게살

    샘표식품 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " S "를 확인하세요
    ※결제시 계산대에서 수령

    샘표 진간장 금 F-3(1.7L+860ml) 10,780원

    씨제이제일제당 전품목 4만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " C "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    1,000원 할인
    백설 고기양념 4종(각 500g) 각3,480원
    ※돼지갈비/돼지불고기/소갈비/소불고기

    CJ 비비고 왕교자(350g×3입) 9,980원

    롯데웰푸드 행사상품 3만원 이상 구매시 10,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " X "를 확인하세요
    ※결제시 계산대에서 수령

    1+1
    롯데 식사이론 대파고기 만두(700g) 9,980원

    롯데 의성마늘 순한 비엔나/후랑크(상품별 상이) 6,980/7,980원

    사조대림 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    1,980원 할인
    해표 식용유(1.7L) 6,120원

    사조대림 육즙팡 동그랑땡(600g)/미니 마늘숙성 떡갈비(540g) 5,980/6,980원

    대상주식회사 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " Z "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    청정원 호밍스 언양식 바싹 불고기(270g)/평양식 만둣국(340g) 7,900/9,980원

    1+1
    청정원 국물내기 한알 4종(상품별 규격 상이) 각9,900원
    ※교차구매 가능
    ※멸치디포리/사골/야채/황태

    세계맥주 골라담기 5캔 11,000원

    5캔 구매시
    세계맥주 골라담기(상품별 용량 상이) 11,000원
    ※1캔 구매시 각 2,280~3,000원

    에비스 미니잔 패키지(350ml×6) 12,800원

    L.POINT 3,900원
    짐빔 화이트 하이볼잔 패키지(750ml) 29,900원

    L.POINT 3,000원
    스카치블루 클래식(700ml) 24,900원

    1+1
    다우니 섬유유연제 8종(용기 2L/리필 2.6L) 각19,600/23,300원 ※동일 가격간 교차구매 가능

    2개 이상 50%
    피지 모락셀라 액체세제(상품별 상이) 2개 이상 구매시 1개당 각14,950원
    ※1개 구매시 각29,900원
    ※허브향/코튼향 ※입점점에 한함 ※교차구매 가능

    2개 이상 60%
    프릴 주방세제 전품목(용기 700ml/리필 1L) 2개 이상 구매시 1개당 각3,800원 
    ※1개 구매시 각9,500원
    ※교차구매 가능
    ※기간 : 1/23(목)~1/26(일)

    2개 이상 50%
    엘라스틴 퍼퓸 샴푸/컨디셔너(각 1,100ml) 2개 이상 구매시 1개당 각6,450원 
    ※1개 구매시 각12,900원 ※라벤더/로즈
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 60%
    케라시스 클린노트&케라틴본드 샴푸/트리트먼트 8종(상품별 상이) 2개 이상 구매시 1개당 각5,160~7,160원
    ※1개 구매시 각12,900~17,900원 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    온더바디 리얼모이스처 바디워시 (각 900g) 2개 이상 구매시 1개당 각4,990원 
    ※1개 구매시 각9,980원
    ※피오니/웜코튼/코코넛향
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    브레프 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,250~4,450원
    ※1개 구매시 각4,500~8,900원
    ※교차구매 가능

    2개 이상 50%
    자연퐁 전품목(상품별 상이) 2개 이상 구매시 1개당 각3,300~9,900원
    ※1개 구매시 각6,600~19,800원
    ※해당상품에 한함 ※교차구매 가능

    롯데/신한/NH농협/삼성카드 1,000원 할인
    잘풀리는집 대용량 키친타월(150매×12롤) 7,900원

    나무야 깨끗한 무향 3겹 화장지(27m×30롤) 9,900원

    리엔 흑모비책 염색약(각 120g×2) 각10,900원

    최대7,970원 할인
    디펜드 언더웨어 12종(상품별 규격 상이) 각9,000~15,930원

    더페이스샵, 비욘드, 이자녹스, 수려한
    해당 브랜드 구매고객 롯데상품권 증정
    ※기간 : 1/23(목)~1/27(월)
    ※도와드리겠습니다 센터 수령
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※점별 입점점/단일 브랜드에 한함

    5만원 이상 구매시 5,000원 롯데상품권 증정! 더페이스샵, 비욘드

    10만원 이상 구매시 10,000원 롯데상품권 증정! 이자녹스, 수려한

    깨끗한 나라 해당상품 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉠ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    순수한면 생리대 8종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,000~7,950원 
    ※1개 구매시 각8,000~15,900원 ※교차구매 가능

    쉬크 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉷ "를 확인하세요
    ※결제시 계산대에서 수령

    쉬크 면도용품 5종(상품별 상이) 각3,900~20,900원

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    40% 테팔 IH 쏘라이트 프라이팬/테팔 IH 티타늄 블라썸 냄비(상품별 상이) 25,680~34,680/26,880~35,880원

    최대 50%
    해피콜 IH 루시드 프라이팬/해피콜 IH 온드 냄비(상품별 상이) 17,450~26,950/26,340~38,340원

    50%
    풍년 마레러스터 IH 압력솥(6인) 52,900원

    포트메리온 보타닉가든(상품별 상이) 롯데/신한/NH농협/삼성카드 최대 50%
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 50%
    글라스락 퓨어오븐(상품별 상이) 3,450~8,450원
    ※기간 : 1/2(목)~1/30(목)

    1,800원 할인
    맥스 폭발방지 CRV부탄가스(4P) 4,990원 ※기간 : 1/9(목)~1/30(목)

    1+1 기획팩
    테이팩스 니트릴 장갑 1+1 기획팩 8,900원

    2개 이상 40%
    크린랩 레이스 고무장갑 2개 이상 구매시 1개당 각1,850~2,150원 ※1개 구매시 각3,090~3,590원 ※교차구매 가능

    최대 4,900원 할인
    오늘좋은 촘촘누비 베개/패드(베개 : 40×60cm/패드 : 140×190cm) 12,000/15,000원
    ※점별 재고 상이 ※해당상품에 한함

    20%
    오늘좋은 클라우드 경추베개(각 2P) 14,320/17,520원 
    ※소 : 40×60cm/대 : 50×70cm
    ※점별 재고 상이 ※해당상품에 한함

    30%
    오늘좋은 고중량 세면타월(각 6P) 각17,430원 ※그레이/라이트브라운/라이트블루
    ※점별 재고 상이 ※해당상품에 한함

    20%
    대림바스 샤워헤드(무광블랙/무광 화이트) 11,920원
    ※입점점에 한함 ※해당상품에 한함

    30%
    룸바이홈 핸드카트 5종(상품별 상이) 18,130~48,930원 ※점별 입점품목 상이

    40%
    아이리스 시스템 수납함(중형 3호/45×35×30cm) 13,740원

    2+1 기획
    10,000원 할인
    명절 선물세트 코코도르 BEST 향 기획 디퓨저(500ml×3개입) 19,900원 ※점별 재고 상이 ※조기 품절될 수 있습니다 ※기간 : 1/16(목)~2/12(수)

    10+10 기획팩
    1,000원 할인
    에너자이저 맥스 기획팩 10+10입(AA/AAA) 각11,900원

    쿡셀 행사상품 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : '24/12/26(목)~'25/1/30(목)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 " ㉪ "를 확인하세요

    20% 쿡셀 프라이팬/냄비(상품별 상이) 31,120~50,240원

    3M 청소/욕실용품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 1/16(목)~1/30(목)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " M "를 확인하세요
    ※결제시 계산대에서 수령

    리필 추가 증정
    3M 중/대형 테이프 클리너 팩/클린 컷 롱핸들 테이프 클리너(대)(상품별 상이) 14,900/19,240/20,780원
    ※해당상품에 한함 ※매장에 더많은 상품이 준비되어 있습니다

    오늘좋은 브랜드 패션잡화 클리어런스
    ※점별 재고수량 상이 ※매장에 더 많은 클리어런스 행사상품이 준비되어 있습니다

    겨울 이불/패브릭 소품 클리어런스(상품별 상이) 각3,000~35,000원 ※해당상품에 한함 ※재고 소진시 자동 종료

    2개 이상 30%
    여성 란제리 클리어런스(각 1입) 2개 이상 구매시 1개당 각4,930~16,730원 ※1개 구매시 각6,900~23,900원 ※교차구매 가능

    방한 패션잡화클리어런스(장갑/모자/가방/머플러/넥워머 등) 각3,000~15,000원

    네파/MLB/JEEP 방한 슬리퍼/샌들 각10,000원 ※행사상품에 한함 ※점별 재고 상이

    오늘좋은 겨울 거실화(상품별 상이) 각5,000/7,000원
    ※점별 재고 상이 ※해당상품에 한함 ※재고 소진시 조기종료

    인기 고양이 사료&간식 30%

    30%
    템테이션 8종&쉬바 캔/파우치 20종(상품별 상이) 1,260~3,010원 ※입점점 상이

    HAPPY ToysRus Day

    레고 쟌의 울트라 합체 로봇 외 147종(상품별 상이) 5,900~279,900원 ※입점점에 한함

    슈팅스타 티니핑 슈팅스타팩트(3세 이상/26×8×28cm) 93,900원
    ※입점점에 한함 ※점별 재고 상이

    L.POINT 20% -> 롯데/신한/NH농협/삼성카드 10% 추가할인
    실바니안 행복한 꿈의 궁전(5537)(3세 이상/38×18×35cm) 53,928원
    ※입점점에 한함
    ※점별 재고 상이 ※페이지 하단 카드할인 세부내용 참고

    L.POINT 20% -> 롯데/신한/NH농협/삼성카드 10% 추가할인
    헬로카봇 스타가디언(37개월 이상/59×13.5×42cm) 126,720원
    ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

    20% 타요 천하장사 중장비 디럭스 놀이세트(37개월 이상/35×19×34cm) 44,800원 ※입점점에 한함

    11,000원 할인 아트샌드 디럭스 세트(3세 이상/45×7.5×38cm) 43,900원 ※입점점에 한함

    20% 그래비트랙스 코어 스타터 : 밸런스 외(상품별 상이) 135,120원 ※입점점에 한함

    스위치 동키콩 리턴즈 HD(전체 이용가) 64,800원

    동키콩 리턴즈 HD 구매시 에코백 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1개)
    ※소진시 증정행사 자동종료
    ※구분자 " D "를 확인하세요

    롯데마트&MAXX카드 롯데마트맥스&신한카드 롯데카드 비씨카드 KB국민카드 신한카드 NH농협카드 하나카드 삼성카드 우리카드 IBK기업은행 JB카드 KJ카드
    최대 50만원 상품권 증정 또는 즉시할인
    최대 30% 할인 혜택 
    L.POINT 회원 특별할인 ※해당상품에 한함
    구매 수량에 따라 덤 증정 하나 더 ※해당상품에 한함

    ▶기간 : 1/16(목) ~ 1/29(수) ※상품권 증정 프로모션은 변경될 수 있습니다 ※상품권 증정 혜택과 중복 제공 불가

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2025. 1. 23(목) ~ 2025. 1. 30(목) < 비신선식품 적용기간 : 2025. 1. 16(목) ~ 1. 30(목) >
    ·제타플렉스 잠실점

    롯데마트
    설레는 설날, 장보기 대전!

    수입 찜갈비/호주산 와규 윗등심(각 100g/냉장/원산지 별도표기) L.POINT 최대 40%

    LA갈비(1kg/냉동/원산지 별도표기) L.POINT 10,000원 할인
 
    수산대전 20% -> 롯데/신한/NH농협/삼성카드 20% 추가할인
    봉지굴 전품목
    ※수산대전 : 1/23(목) ~ 1/29(수),카드할인 : 1/23(목) ~ 1/30(목)
    ※봉지굴 전품목 해당
    ※L.POINT회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    한우 국거리/불고기(1등급)ㄴ(각 100g/냉장/국내산 한우고기) L.POINT 30%

    L.POINT 20%
    간편 수제 명태살/대구살/홍메기전(각 500g/냉동/원산지 별도표기) 7,920/14,320/11,920원

    부세(마리/해동/중국산) 4,490원

    도미(마리/해동/국산) 6,990원

    물가안정 참조기(대)(마리/해동/국산) 2,990원

    농활할인 20%
    제주 한라봉(2kg/박스/국산) 18,960원 ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    
    농활할인 20%
    겨울 시금치(단/국산) 3,496원
    ※조기 품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    농활할인 20%
    데친 고사리/깐 도라지(300g/250g, 팩/국산) 각7,992원
    ※기간 : 1/25(토) ~ 1/30(목)
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    우리쌀 떡국떡(100g/원산지 별도표기) 600~1,290원
    ※기간 : 1/27(월) ~ 1/29(수)

    L.POINT 2,000원
    제수용 황태포(60g 내외/75g 내외, 1미/원산지 별도표기) 4,990/5,990원

    제수용 사과(3입/팩/국산) 15,990원

    제수용 배(3입/팩/국산) 25,990원

    제수용 감(5입/봉/국산) 9,990원

    L.POINT 2,000원
    제수용 곶감(8입/팩/국산) 7,990원

    농활할인 20%
    밤(1kg/봉/국산) 8,720원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    2개 이상 최대2,480원 할인
    풀무원 적전류 5종(상품별 상이) 2개 이상 구매시 1개당 각5,000원 ※1개 구매시 각 5,980~7,480원 ※교차구매 가능

    롯데 의성마늘햄 外 햄/맛살/단무지(상품별 상이) 1,290~10,900원

    420원 할인
    오뚜기 부침/튀김가루(각 1kg) 각2,980원

    최대1,200원 할인
    오뚜기 옛날 자른당면(300g/500g) 각3,980/6,480원

    740원 할인
    팔도 비락식혜/비락식혜 제로/수정과(각 1.5L) 각2,980원
    
    별빛청하 변온잔 패키지(295ml×4) 7,960원

    2025 롯데마트 물가잡기 캠페인 
    매주 꼭 필요한 상품을 THE HOT하게 제안합니다

    이번주 핫프라이스

    롯데/신한/NH농협/삼성카드 6,000원 할인
    국내산 돼지 갈비(700g/냉장/국내산) 8,900원
    ※기간 : 1/23(목) ~ 1/27(월)
    ※제주점 제외 ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/NH농협/삼성카드 30% -> 수산대전 20%
    국산 데친문어(100g/냉장/국산) 2,492원
    ※수산대전 : 1/23(목) ~ 1/29(수), 카드할인 : 1/23(목) ~ 1/30(목)
    ※L.POINT회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    2,500원 할인 -> 롯데/신한/NH농협/삼성카드 500원 추가할인
    목우촌 주부9단 살코기햄(1kg) 9,980원
    ※기간 : 1/23(목) ~ 1/30(목)
    ※페이지 하단 카드할인 세부내용 참고

    이달의 핫 PB

    3,000원 할인
    요리하다 LA식 꽃갈비(600g) 14,900원

    1,000원 할인
    요리하다 동태전(300g) 4,990원

    1,000원 할인
    오늘좋은 우리쌀 미니약과(500g) 3,990원

    1,000원 할인
    오늘좋은 식혜(238ml×12입) 5,990원

    공구핫딜

    공구핫딜 고래사 꼬치어묵(526g) 6,940원

    공구핫딜 샤워메이트 플라워퍼퓸 바디워시(1,200ml, 옐로우) 4,900원

    공구핫딜 명진 고무장갑 3켤레 기획+속장갑 증정(각 3켤레, 중/대) 각5,000원

    ※정상가란? 2025년 1월 2일(목) ~ 2025년 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  
  </div>`,
  `<div>
    L.POINT 40%
    1등급 브랜드 돼지고기 삼겹살/목심(각 100g/냉장/국내산) 각2,928원
    ※제주점 제외 ※조기 품절될 수 있습니다

    L.POINT 50%
    창녕 양파품은 한우 불고기(700g/냉장/원산지 별도표기) 15,900원

    L.POINT 20,000원
    요리하다 양념 LA 꽃갈비(800g/냉장/원산지 별도표기) 29,900원
    ※조기 품절될 수 있습니다

    L.POINT 3,000원
    훈제오리 슬라이스(500g/냉장/원산지 별도표기) 11,990원

    L.POINT 50%
    국산 데친 홍게(8마리/냉장/국산) 39,950원
    ※기간 : 1/24(금)~1/26(일) ※일부점에 한함

    롯데/신한/NH농협/삼성카드 20% -> 수산대전 20%
    완도 활 전복(왕/특)(마리/냉장/국산) 3,904/2,656원
    ※수산대전 : 1/23(목)~1/29(수)/카드할인 : 1/23(목)~1/30(목)
    ※L.POINT회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    물가안정 갈치/오징어(각 1마리, 해동, 갈치: 국산/오징어 : 원양산) 각2,900원

    L.POINT 20%
    도미회(300g 내외/냉장 /도미 : 국산) 34,800원

    L.POINT 20%
    껍질벗긴 새우(750g/냉동/원산지 별도표기) 19,120원

    L.POINT 30%
    내가만드는 연어(횟감용/구이용)(각 100g/냉장/노르웨이산) 3,990/3,710원
    ※조기 품절될 수 있습니다

    L.POINT 30%
    파타고니아 생연어 필렛 대용량 세트(800g 내외/냉장/연어 : 칠레산) 38,430원

    2개 이상 각500원 할인
    대천 재래, 파래/곱창 식탁김(각 5g×20봉/원산지 별도표기) 2개 이상 구매시 1개당 각6,990/7,990원 ※교차구매 가능
    ※1개 구매시 각7,490/8,490원

    농활할인 20%
    대파(700g/국산) 2,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    GAP 친환경 새송이 버섯(300g×2봉/국산) 4,990원

    페루 애플망고(6입/박스/페루산) 29,900원

    농활할인 20%
    제주 타이벡 감귤(2kg/박스/국산) 14,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    L.POINT 2,000원
    스위텔 토마토(800g/팩/국산) 7,990원

    옛날 두마리 치킨(1팩/국내산 계육) 16,990원

    반값득템초밥(30입/1팩/원산지 별도표기) 14,990원

    L.POINT 20% 해물 양장피(1팩/원산지 별도표기) 15,992원

    한입 쏙 진도 대파 떡갈비(1팩/원산지 별도표기) 7,990원

    모둠전&나물(100g/원산지 별도표기) 각3,900원
    ※기간 : 1/27(월)~1/29(수)
    ※일부품목에 한함 ※반찬 운영점 한함

    하나사면 하나 더 1+1

    1+1
    동원 리얼 크랩스/랍스터(상품별 상이) 각5,980원 ※교차구매 가능

    1+1
    하림 텐더스틱(550g) 11,480원

    1+1
    오뚜기 피자 4종(상품별 규격 상이) 각9,980원
    ※ 콤비네이션/불고기/마르게리타/콘치즈 ※교차구매 가능

    1+1
    빙그레 요플레 4입 5종(상품별 상이) 각3,780원 ※교차구매 가능

    1+1
    하겐다즈 파인트 전품목(상품별 규격 상이) 각15,500원 ※교차구매 가능
    ※하겐다즈 파인트(마카다미아넛) 外 11종

    1+1
    큐원 알룰로스 2종(각 540g) 각9,680원
    ※난소화성말토덱스트린/프리바이오틱스
    ※교차구매 가능

    1+1
    아이배냇 이유식/유아간식 63종(상품별 상이) 각1,500~14,500원 ※동일가격 간 교차구매 가능

    1+1
    델몬트 오렌지/포도/망고(각 1.8L) 각5,480원 ※교차구매 가능

    1+1
    자임 하루하나 유기농 레몬즙/레몬생강즙(각 14입) 각15,900원 ※교차구매 가능

    2개 이상 구매시 더 싸게

    2개 이상 각1,980원 할인
    풀무원 24시 즉석우동 2종 (상품별 상이) 2개 이상 구매시 1개당 각5,000원 
    ※1개 구매시 각6,980원
    ※교차구매 가능

    2개 이상 각500원 할인
    CJ 비비고 동그랑땡/남도 떡갈비 外 4종(상품별 규격 상이) 2개 이상 구매시 1개당 각7,980~8,980원
    ※1개 구매시 각8,480~9,480원 ※교차구매 가능

    2개 이상 각580원 할인
    CJ 육공육 후랑크 2종(255g/275g) 2개 이상 구매시 1개당 각5,000원 ※1개 구매시 각5,580원 ※교차구매 가능

    2개 이상 10%
    농심 봉지라면 전품목 43종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,422~14,040원 
    ※1개 구매시 각1,580~15,600원 ※교차구매 가능 ※농심 농심라면 4개입 外 42종

    2개 이상 10%
    농심 인기 스낵 번들(상품별 상이) 2개 이상 구매시 1개당 각2,682원 ※1개 구매시 각2,980원
    ※새우깡/꿀꽈배기/포테토칩 ※교차구매 가능

    2개 이상 20%
    매일 바이오 2종(각 450g) 2개 이상 구매시 1개당 각2,936원 ※1개 구매시 각3,670원
    ※플레인/플레인저지방 ※교차구매 가능

    2개 이상 50%
    서울 까요까요 4종(상품별 용량 상이) 2개 이상 구매시 1개당 각2,290원 ※1개 구매시 각4,580원
    ※딸기/세븐베리/초코/플레인 ※교차구매 가능

    2개 이상 20%
    요즘 그릭요거트 5종(상품별 용량 상이) 2개 이상 구매시 1개당 각2,800~3,920원 
    ※1개 구매시 각3,500~4,900원 ※교차구매 가능

    2+1
    삼립 호빵 6종(상품별 용량 상이) 5,380~5,880원
    ※3개 구매시 34% 할인 적용 ※교차구매 가능

    2+1
    담터 쌍화차 플러스/생강차 플러스(각 50입) 각13,500원 ※교차구매 가능

    칠갑 우리쌀 떡국/햅쌀떡(1.2kg/1kg) 5,980/7,480원

    20%
    고래사 국탕용어묵(385g) 6,800원

    서울 흰 우유(2.3L) 5,980원

    동원 비피더스 명장기획(125ml×12입) 4,780원

    1,910원 할인
    칠성사이다+칠성사이다/칠성사이다+펩시콜라(각 1.8L×2입) 4,450원

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    롯데 캐치티니핑×스트로베리 기획 과자/베이커리 9종(상품별 규격 상이) 각1,440~7,980원

    오리온 봄 스트로베리 기획 3종(상품별 규격 상이) 각2,400/2,720/4,800원 ※오감자/초코송이/초코파이 

    15% 크라운 참쌀 선과/설병(253g/270g) 각3,672원

    2개 이상 40%
    내추럴박스 레몬즙/레몬생강즙(각 500ml) 2개 이상 구매시 1개당 각5,994원
    ※교차구매 가능 ※1개 구매시 각9,990원

    1+1
    상쾌환 스틱 4종(각 18g×3입) 9,980원 ※교차구매 가능
    ※복숭아맛/망고맛/샤인머스캣맛/사과맛

    동원에프앤비 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉢ "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    1+1
    동원 참치액 3종(각 500g) 각7,980원
    ※해물/진/순 ※교차구매 가능

    1+1
    동원 양반 비빔드밥 6종(상품별 상이) 각4,980원 ※교차구매 가능

    1+1
    동원 양반 상온국물요리 6종(상품별 규격 상이) 각8,980원
    ※수라 왕갈비탕 460g 외 5종 ※교차구매 가능

    동원 양반 완자 2종(각 850g) 8,480/9,980원 ※고기완자/버섯완자

    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정 사조대림 전품목 
    4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " Y "를 확인하세요
    ※결제시 계산대에서 수령

    L.POINT 5,400원
    오뚜기 옛날 참기름(450ml) 7,480원

    2개 이상 10%
    오뚜기 옛날 잡채/매콤 잡채(각 4개입) 2개 이상 구매시 1개당 각5,382원 ※1개 구매시 각5,980원 ※교차구매 가능
      
    1+1
    오뚜기 가쓰오 우동 2인(466g) 6,980원

    오뚜기 XO 교자 3입 3종(상품별 규격 상이) 각8,980원 ※고기/김치/새우&홍게살

    샘표식품 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " S "를 확인하세요
    ※결제시 계산대에서 수령

    샘표 진간장 금 F-3(1.7L+860ml) 10,780원

    씨제이제일제당 전품목 4만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " C "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    1,000원 할인
    백설 고기양념 4종(각 500g) 각3,480원
    ※돼지갈비/돼지불고기/소갈비/소불고기

    CJ 비비고 왕교자(350g×3입) 9,980원

    롯데웰푸드 행사상품 3만원 이상 구매시 10,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " X "를 확인하세요
    ※결제시 계산대에서 수령

    1+1
    롯데 식사이론 대파고기 만두(700g) 9,980원

    롯데 의성마늘 순한 비엔나/후랑크(상품별 상이) 6,980/7,980원

    사조대림 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    1,980원 할인
    해표 식용유(1.7L) 6,120원

    사조대림 육즙팡 동그랑땡(600g)/미니 마늘숙성 떡갈비(540g) 5,980/6,980원

    대상주식회사 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " Z "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    청정원 호밍스 언양식 바싹 불고기(270g)/평양식 만둣국(340g) 7,900/9,980원

    1+1
    청정원 국물내기 한알 4종(상품별 규격 상이) 각9,900원
    ※교차구매 가능
    ※멸치디포리/사골/야채/황태

    세계맥주 골라담기 5캔 11,000원

    5캔 구매시
    세계맥주 골라담기(상품별 용량 상이) 11,000원
    ※1캔 구매시 각 2,280~3,000원

    에비스 미니잔 패키지(350ml×6) 12,800원

    1+1
    다우니 섬유유연제 8종(용기 2L/리필 2.6L) 각19,600/23,300원 ※동일 가격간 교차구매 가능

    2개 이상 50%
    피지 모락셀라 액체세제(상품별 상이) 2개 이상 구매시 1개당 각14,950원
    ※1개 구매시 각29,900원
    ※허브향/코튼향 ※입점점에 한함 ※교차구매 가능

    2개 이상 60%
    프릴 주방세제 전품목(용기 700ml/리필 1L) 2개 이상 구매시 1개당 각3,800원 
    ※1개 구매시 각9,500원
    ※교차구매 가능
    ※기간 : 1/23(목)~1/26(일)

    2개 이상 50%
    엘라스틴 퍼퓸 샴푸/컨디셔너(각 1,100ml) 2개 이상 구매시 1개당 각6,450원 
    ※1개 구매시 각12,900원 ※라벤더/로즈
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 60%
    케라시스 클린노트&케라틴본드 샴푸/트리트먼트 8종(상품별 상이) 2개 이상 구매시 1개당 각5,160~7,160원
    ※1개 구매시 각12,900~17,900원 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    온더바디 리얼모이스처 바디워시 (각 900g) 2개 이상 구매시 1개당 각4,990원 
    ※1개 구매시 각9,980원
    ※피오니/웜코튼/코코넛향
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    브레프 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,250~4,450원
    ※1개 구매시 각4,500~8,900원
    ※교차구매 가능

    2개 이상 50%
    자연퐁 전품목(상품별 상이) 2개 이상 구매시 1개당 각3,300~9,900원
    ※1개 구매시 각6,600~19,800원
    ※해당상품에 한함 ※교차구매 가능

    롯데/신한/NH농협/삼성카드 1,000원 할인
    잘풀리는집 대용량 키친타월(150매×12롤) 7,900원

    나무야 깨끗한 무향 3겹 화장지(27m×30롤) 9,900원

    리엔 흑모비책 염색약(각 120g×2) 각10,900원

    최대7,970원 할인
    디펜드 언더웨어 12종(상품별 규격 상이) 각9,000~15,930원

    더페이스샵, 비욘드, 이자녹스, 수려한
    해당 브랜드 구매고객 롯데상품권 증정
    ※기간 : 1/23(목)~1/27(월)
    ※도와드리겠습니다 센터 수령
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※점별 입점점/단일 브랜드에 한함

    5만원 이상 구매시 5,000원 롯데상품권 증정! 더페이스샵, 비욘드

    10만원 이상 구매시 10,000원 롯데상품권 증정! 이자녹스, 수려한

    깨끗한 나라 해당상품 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉠ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    순수한면 생리대 8종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,000~7,950원 
    ※1개 구매시 각8,000~15,900원 ※교차구매 가능

    쉬크 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉷ "를 확인하세요
    ※결제시 계산대에서 수령

    쉬크 면도용품 5종(상품별 상이) 각3,900~20,900원

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    40% 테팔 IH 쏘라이트 프라이팬/테팔 IH 티타늄 블라썸 냄비(상품별 상이) 25,680~34,680/26,880~35,880원

    최대 50%
    해피콜 IH 루시드 프라이팬/해피콜 IH 온드 냄비(상품별 상이) 17,450~26,950/26,340~38,340원

    50%
    풍년 마레러스터 IH 압력솥(6인) 52,900원

    포트메리온 보타닉가든(상품별 상이) 롯데/신한/NH농협/삼성카드 최대 50%
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 50%
    글라스락 퓨어오븐(상품별 상이) 3,450~8,450원
    ※기간 : 1/2(목)~1/30(목)

    1,800원 할인
    맥스 폭발방지 CRV부탄가스(4P) 4,990원 ※기간 : 1/9(목)~1/30(목)

    1+1 기획팩
    테이팩스 니트릴 장갑 1+1 기획팩 8,900원

    2개 이상 40%
    크린랩 레이스 고무장갑 2개 이상 구매시 1개당 각1,850~2,150원 ※1개 구매시 각3,090~3,590원 ※교차구매 가능

    최대 4,900원 할인
    오늘좋은 촘촘누비 베개/패드(베개 : 40×60cm/패드 : 140×190cm) 12,000/15,000원
    ※점별 재고 상이 ※해당상품에 한함

    20%
    오늘좋은 클라우드 경추베개(각 2P) 14,320/17,520원 
    ※소 : 40×60cm/대 : 50×70cm
    ※점별 재고 상이 ※해당상품에 한함

    30%
    오늘좋은 고중량 세면타월(각 6P) 각17,430원 ※그레이/라이트브라운/라이트블루
    ※점별 재고 상이 ※해당상품에 한함

    20%
    대림바스 샤워헤드(무광블랙/무광 화이트) 11,920원
    ※입점점에 한함 ※해당상품에 한함

    30%
    룸바이홈 핸드카트 5종(상품별 상이) 18,130~48,930원 ※점별 입점품목 상이

    40%
    아이리스 시스템 수납함(중형 3호/45×35×30cm) 13,740원

    2+1 기획
    10,000원 할인
    명절 선물세트 코코도르 BEST 향 기획 디퓨저(500ml×3개입) 19,900원 ※점별 재고 상이 ※조기 품절될 수 있습니다 ※기간 : 1/16(목)~2/12(수)

    10+10 기획팩
    1,000원 할인
    에너자이저 맥스 기획팩 10+10입(AA/AAA) 각11,900원

    쿡셀 행사상품 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : '24/12/26(목)~'25/1/30(목)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 " ㉪ "를 확인하세요

    20% 쿡셀 프라이팬/냄비(상품별 상이) 31,120~50,240원

    3M 청소/욕실용품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 1/16(목)~1/30(목)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " M "를 확인하세요
    ※결제시 계산대에서 수령

    리필 추가 증정
    3M 중/대형 테이프 클리너 팩/클린 컷 롱핸들 테이프 클리너(대)(상품별 상이) 14,900/19,240/20,780원
    ※해당상품에 한함 ※매장에 더많은 상품이 준비되어 있습니다

    세상의 모든 스포츠, 데카트론!
    스포츠의 경이로움으로 사람들을 움직인다

    성인 스키 넥워머 퍼스트 히트 4,900원
 
    러닝 보온 헤드밴드 8,900원

    3,100원 할인 피트니스 풀업 고무 밴드(35kg) 13,900원

    4,000원 할인 아동 스키 방한 방수 장갑 100 16,900원

    15,000원 할인 아동 스키 방한 방수 바지 100 24,900원

    53,000원 할인
    아동 벽걸이 농구 골대 세트 29,900원

    인기 고양이 사료&간식 30%

    30%
    템테이션 8종&쉬바 캔/파우치 20종(상품별 상이) 1,260~3,010원 ※입점점 상이

    HAPPY ToysRus Day

    레고 쟌의 울트라 합체 로봇 외 147종(상품별 상이) 5,900~279,900원 ※입점점에 한함

    슈팅스타 티니핑 슈팅스타팩트(3세 이상/26×8×28cm) 93,900원
    ※입점점에 한함 ※점별 재고 상이

    L.POINT 20% -> 롯데/신한/NH농협/삼성카드 10% 추가할인
    실바니안 행복한 꿈의 궁전(5537)(3세 이상/38×18×35cm) 53,928원
    ※입점점에 한함
    ※점별 재고 상이 ※페이지 하단 카드할인 세부내용 참고

    L.POINT 20% -> 롯데/신한/NH농협/삼성카드 10% 추가할인
    헬로카봇 스타가디언(37개월 이상/59×13.5×42cm) 126,720원
    ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

    20% 타요 천하장사 중장비 디럭스 놀이세트(37개월 이상/35×19×34cm) 44,800원 ※입점점에 한함

    11,000원 할인 아트샌드 디럭스 세트(3세 이상/45×7.5×38cm) 43,900원 ※입점점에 한함

    20% 그래비트랙스 코어 스타터 : 밸런스 외(상품별 상이) 135,120원 ※입점점에 한함

    스위치 동키콩 리턴즈 HD(전체 이용가) 64,800원

    동키콩 리턴즈 HD 구매시 에코백 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1개)
    ※소진시 증정행사 자동종료
    ※구분자 " D "를 확인하세요

    롯데마트&MAXX카드 롯데마트맥스&신한카드 롯데카드 비씨카드 KB국민카드 신한카드 NH농협카드 하나카드 삼성카드 우리카드 IBK기업은행 JB카드 KJ카드
    최대 50만원 상품권 증정 또는 즉시할인
    최대 30% 할인 혜택 
    L.POINT 회원 특별할인 ※해당상품에 한함
    구매 수량에 따라 덤 증정 하나 더 ※해당상품에 한함

    ▶기간 : 1/16(목) ~ 1/29(수) ※상품권 증정 프로모션은 변경될 수 있습니다 ※상품권 증정 혜택과 중복 제공 불가

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
];

const zettaSeoulData = [
  `<h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2025. 1. 23(목) ~ 2025. 1. 30(목) < 비신선식품 적용기간 : 2025. 1. 16(목) ~ 1. 30(목) >
    ·제타플렉스 서울역점

    롯데마트
    설레는 설날, 장보기 대전!

    수입 찜갈비/호주산 와규 윗등심(각 100g/냉장/원산지 별도표기) L.POINT 최대 40%

    LA갈비(1kg/냉동/원산지 별도표기) L.POINT 10,000원 할인
 
    수산대전 20% -> 롯데/신한/NH농협/삼성카드 20% 추가할인
    봉지굴 전품목
    ※수산대전 : 1/23(목) ~ 1/29(수),카드할인 : 1/23(목) ~ 1/30(목)
    ※봉지굴 전품목 해당
    ※L.POINT회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    한우 국거리/불고기(1등급)ㄴ(각 100g/냉장/국내산 한우고기) L.POINT 30%

    L.POINT 20%
    간편 수제 명태살/대구살/홍메기전(각 500g/냉동/원산지 별도표기) 7,920/14,320/11,920원

    부세(마리/해동/중국산) 4,490원

    도미(마리/해동/국산) 6,990원

    물가안정 참조기(대)(마리/해동/국산) 2,990원

    농활할인 20%
    제주 한라봉(2kg/박스/국산) 18,960원 ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    
    농활할인 20%
    겨울 시금치(단/국산) 3,496원
    ※조기 품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    농활할인 20%
    데친 고사리/깐 도라지(300g/250g, 팩/국산) 각7,992원
    ※기간 : 1/25(토) ~ 1/30(목)
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    우리쌀 떡국떡(100g/원산지 별도표기) 600~1,290원
    ※기간 : 1/27(월) ~ 1/29(수)

    L.POINT 2,000원
    제수용 황태포(60g 내외/75g 내외, 1미/원산지 별도표기) 4,990/5,990원

    제수용 사과(3입/팩/국산) 15,990원

    제수용 배(3입/팩/국산) 25,990원

    제수용 감(5입/봉/국산) 9,990원

    L.POINT 2,000원
    제수용 곶감(8입/팩/국산) 7,990원

    농활할인 20%
    밤(1kg/봉/국산) 8,720원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    2개 이상 최대2,480원 할인
    풀무원 적전류 5종(상품별 상이) 2개 이상 구매시 1개당 각5,000원 ※1개 구매시 각 5,980~7,480원 ※교차구매 가능

    롯데 의성마늘햄 外 햄/맛살/단무지(상품별 상이) 1,290~10,900원

    420원 할인
    오뚜기 부침/튀김가루(각 1kg) 각2,980원

    최대1,200원 할인
    오뚜기 옛날 자른당면(300g/500g) 각3,980/6,480원

    740원 할인
    팔도 비락식혜/비락식혜 제로/수정과(각 1.5L) 각2,980원
    
    별빛청하 변온잔 패키지(295ml×4) 7,960원

    2025 롯데마트 물가잡기 캠페인 
    매주 꼭 필요한 상품을 THE HOT하게 제안합니다

    이번주 핫프라이스

    롯데/신한/NH농협/삼성카드 6,000원 할인
    국내산 돼지 갈비(700g/냉장/국내산) 8,900원
    ※기간 : 1/23(목) ~ 1/27(월)
    ※제주점 제외 ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/NH농협/삼성카드 30% -> 수산대전 20%
    국산 데친문어(100g/냉장/국산) 2,492원
    ※수산대전 : 1/23(목) ~ 1/29(수), 카드할인 : 1/23(목) ~ 1/30(목)
    ※L.POINT회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    2,500원 할인 -> 롯데/신한/NH농협/삼성카드 500원 추가할인
    목우촌 주부9단 살코기햄(1kg) 9,980원
    ※기간 : 1/23(목) ~ 1/30(목)
    ※페이지 하단 카드할인 세부내용 참고

    이달의 핫 PB

    3,000원 할인
    요리하다 LA식 꽃갈비(600g) 14,900원

    1,000원 할인
    요리하다 동태전(300g) 4,990원

    1,000원 할인
    오늘좋은 우리쌀 미니약과(500g) 3,990원

    1,000원 할인
    오늘좋은 식혜(238ml×12입) 5,990원

    공구핫딜

    공구핫딜 고래사 꼬치어묵(526g) 6,940원

    공구핫딜 샤워메이트 플라워퍼퓸 바디워시(1,200ml, 옐로우) 4,900원

    공구핫딜 명진 고무장갑 3켤레 기획+속장갑 증정(각 3켤레, 중/대) 각5,000원

    ※정상가란? 2025년 1월 2일(목) ~ 2025년 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>`,
  `<div>
    L.POINT 40%
    1등급 브랜드 돼지고기 삼겹살/목심(각 100g/냉장/국내산) 각2,928원
    ※제주점 제외 ※조기 품절될 수 있습니다

    L.POINT 50%
    창녕 양파품은 한우 불고기(700g/냉장/원산지 별도표기) 15,900원

    L.POINT 20,000원
    요리하다 양념 LA 꽃갈비(800g/냉장/원산지 별도표기) 29,900원
    ※조기 품절될 수 있습니다

    L.POINT 3,000원
    훈제오리 슬라이스(500g/냉장/원산지 별도표기) 11,990원

    L.POINT 50%
    국산 데친 홍게(8마리/냉장/국산) 39,950원
    ※기간 : 1/24(금)~1/26(일) ※일부점에 한함

    롯데/신한/NH농협/삼성카드 20% -> 수산대전 20%
    완도 활 전복(왕/특)(마리/냉장/국산) 3,904/2,656원
    ※수산대전 : 1/23(목)~1/29(수)/카드할인 : 1/23(목)~1/30(목)
    ※L.POINT회원 기간 중 1인 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    물가안정 갈치/오징어(각 1마리, 해동, 갈치: 국산/오징어 : 원양산) 각2,900원

    L.POINT 20%
    도미회(300g 내외/냉장 /도미 : 국산) 34,800원

    L.POINT 20%
    껍질벗긴 새우(750g/냉동/원산지 별도표기) 19,120원

    L.POINT 30%
    내가만드는 연어(횟감용/구이용)(각 100g/냉장/노르웨이산) 3,990/3,710원
    ※조기 품절될 수 있습니다

    L.POINT 30%
    파타고니아 생연어 필렛 대용량 세트(800g 내외/냉장/연어 : 칠레산) 38,430원

    2개 이상 각500원 할인
    대천 재래, 파래/곱창 식탁김(각 5g×20봉/원산지 별도표기) 2개 이상 구매시 1개당 각6,990/7,990원 ※교차구매 가능
    ※1개 구매시 각7,490/8,490원

    농활할인 20%
    대파(700g/국산) 2,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    GAP 친환경 새송이 버섯(300g×2봉/국산) 4,990원

    페루 애플망고(6입/박스/페루산) 29,900원

    농활할인 20%
    제주 타이벡 감귤(2kg/박스/국산) 14,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인

    L.POINT 2,000원
    스위텔 토마토(800g/팩/국산) 7,990원

    옛날 두마리 치킨(1팩/국내산 계육) 16,990원

    반값득템초밥(30입/1팩/원산지 별도표기) 14,990원

    L.POINT 20% 해물 양장피(1팩/원산지 별도표기) 15,992원

    한입 쏙 진도 대파 떡갈비(1팩/원산지 별도표기) 7,990원

    모둠전&나물(100g/원산지 별도표기) 각3,900원
    ※기간 : 1/27(월)~1/29(수)
    ※일부품목에 한함 ※반찬 운영점 한함

    하나사면 하나 더 1+1

    1+1
    동원 리얼 크랩스/랍스터(상품별 상이) 각5,980원 ※교차구매 가능

    1+1
    하림 텐더스틱(550g) 11,480원

    1+1
    오뚜기 피자 4종(상품별 규격 상이) 각9,980원
    ※ 콤비네이션/불고기/마르게리타/콘치즈 ※교차구매 가능

    1+1
    빙그레 요플레 4입 5종(상품별 상이) 각3,780원 ※교차구매 가능

    1+1
    하겐다즈 파인트 전품목(상품별 규격 상이) 각15,500원 ※교차구매 가능
    ※하겐다즈 파인트(마카다미아넛) 外 11종

    1+1
    큐원 알룰로스 2종(각 540g) 각9,680원
    ※난소화성말토덱스트린/프리바이오틱스
    ※교차구매 가능

    1+1
    아이배냇 이유식/유아간식 63종(상품별 상이) 각1,500~14,500원 ※동일가격 간 교차구매 가능

    1+1
    델몬트 오렌지/포도/망고(각 1.8L) 각5,480원 ※교차구매 가능

    1+1
    자임 하루하나 유기농 레몬즙/레몬생강즙(각 14입) 각15,900원 ※교차구매 가능

    2개 이상 구매시 더 싸게

    2개 이상 각1,980원 할인
    풀무원 24시 즉석우동 2종 (상품별 상이) 2개 이상 구매시 1개당 각5,000원 
    ※1개 구매시 각6,980원
    ※교차구매 가능

    2개 이상 각500원 할인
    CJ 비비고 동그랑땡/남도 떡갈비 外 4종(상품별 규격 상이) 2개 이상 구매시 1개당 각7,980~8,980원
    ※1개 구매시 각8,480~9,480원 ※교차구매 가능

    2개 이상 각580원 할인
    CJ 육공육 후랑크 2종(255g/275g) 2개 이상 구매시 1개당 각5,000원 ※1개 구매시 각5,580원 ※교차구매 가능

    2개 이상 10%
    농심 봉지라면 전품목 43종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,422~14,040원 
    ※1개 구매시 각1,580~15,600원 ※교차구매 가능 ※농심 농심라면 4개입 外 42종

    2개 이상 10%
    농심 인기 스낵 번들(상품별 상이) 2개 이상 구매시 1개당 각2,682원 ※1개 구매시 각2,980원
    ※새우깡/꿀꽈배기/포테토칩 ※교차구매 가능

    2개 이상 20%
    매일 바이오 2종(각 450g) 2개 이상 구매시 1개당 각2,936원 ※1개 구매시 각3,670원
    ※플레인/플레인저지방 ※교차구매 가능

    2개 이상 50%
    서울 까요까요 4종(상품별 용량 상이) 2개 이상 구매시 1개당 각2,290원 ※1개 구매시 각4,580원
    ※딸기/세븐베리/초코/플레인 ※교차구매 가능

    2개 이상 20%
    요즘 그릭요거트 5종(상품별 용량 상이) 2개 이상 구매시 1개당 각2,800~3,920원 
    ※1개 구매시 각3,500~4,900원 ※교차구매 가능

    2+1
    삼립 호빵 6종(상품별 용량 상이) 5,380~5,880원
    ※3개 구매시 34% 할인 적용 ※교차구매 가능

    2+1
    담터 쌍화차 플러스/생강차 플러스(각 50입) 각13,500원 ※교차구매 가능

    칠갑 우리쌀 떡국/햅쌀떡(1.2kg/1kg) 5,980/7,480원

    20%
    고래사 국탕용어묵(385g) 6,800원

    서울 흰 우유(2.3L) 5,980원

    동원 비피더스 명장기획(125ml×12입) 4,780원

    1,910원 할인
    칠성사이다+칠성사이다/칠성사이다+펩시콜라(각 1.8L×2입) 4,450원

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    롯데 캐치티니핑×스트로베리 기획 과자/베이커리 9종(상품별 규격 상이) 각1,440~7,980원

    오리온 봄 스트로베리 기획 3종(상품별 규격 상이) 각2,400/2,720/4,800원 ※오감자/초코송이/초코파이 

    15% 크라운 참쌀 선과/설병(253g/270g) 각3,672원

    2개 이상 40%
    내추럴박스 레몬즙/레몬생강즙(각 500ml) 2개 이상 구매시 1개당 각5,994원
    ※교차구매 가능 ※1개 구매시 각9,990원

    1+1
    상쾌환 스틱 4종(각 18g×3입) 9,980원 ※교차구매 가능
    ※복숭아맛/망고맛/샤인머스캣맛/사과맛

    동원에프앤비 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉢ "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    1+1
    동원 참치액 3종(각 500g) 각7,980원
    ※해물/진/순 ※교차구매 가능

    1+1
    동원 양반 비빔드밥 6종(상품별 상이) 각4,980원 ※교차구매 가능

    1+1
    동원 양반 상온국물요리 6종(상품별 규격 상이) 각8,980원
    ※수라 왕갈비탕 460g 외 5종 ※교차구매 가능

    동원 양반 완자 2종(각 850g) 8,480/9,980원 ※고기완자/버섯완자

    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정 사조대림 전품목 
    4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " Y "를 확인하세요
    ※결제시 계산대에서 수령

    L.POINT 5,400원
    오뚜기 옛날 참기름(450ml) 7,480원

    2개 이상 10%
    오뚜기 옛날 잡채/매콤 잡채(각 4개입) 2개 이상 구매시 1개당 각5,382원 ※1개 구매시 각5,980원 ※교차구매 가능
      
    1+1
    오뚜기 가쓰오 우동 2인(466g) 6,980원

    오뚜기 XO 교자 3입 3종(상품별 규격 상이) 각8,980원 ※고기/김치/새우&홍게살

    샘표식품 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " S "를 확인하세요
    ※결제시 계산대에서 수령

    샘표 진간장 금 F-3(1.7L+860ml) 10,780원

    씨제이제일제당 전품목 4만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " C "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    1,000원 할인
    백설 고기양념 4종(각 500g) 각3,480원
    ※돼지갈비/돼지불고기/소갈비/소불고기

    CJ 비비고 왕교자(350g×3입) 9,980원

    롯데웰푸드 행사상품 3만원 이상 구매시 10,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " X "를 확인하세요
    ※결제시 계산대에서 수령

    1+1
    롯데 식사이론 대파고기 만두(700g) 9,980원

    롯데 의성마늘 순한 비엔나/후랑크(상품별 상이) 6,980/7,980원

    사조대림 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    1,980원 할인
    해표 식용유(1.7L) 6,120원

    사조대림 육즙팡 동그랑땡(600g)/미니 마늘숙성 떡갈비(540g) 5,980/6,980원

    대상주식회사 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " Z "를 확인하세요
    ※결제시 계산대에서 수령 ※선물세트 제외

    청정원 호밍스 언양식 바싹 불고기(270g)/평양식 만둣국(340g) 7,900/9,980원

    1+1
    청정원 국물내기 한알 4종(상품별 규격 상이) 각9,900원
    ※교차구매 가능
    ※멸치디포리/사골/야채/황태

    세계맥주 골라담기 5캔 11,000원

    5캔 구매시
    세계맥주 골라담기(상품별 용량 상이) 11,000원
    ※1캔 구매시 각 2,280~3,000원

    에비스 미니잔 패키지(350ml×6) 12,800원

    1+1
    다우니 섬유유연제 8종(용기 2L/리필 2.6L) 각19,600/23,300원 ※동일 가격간 교차구매 가능

    2개 이상 50%
    피지 모락셀라 액체세제(상품별 상이) 2개 이상 구매시 1개당 각14,950원
    ※1개 구매시 각29,900원
    ※허브향/코튼향 ※입점점에 한함 ※교차구매 가능

    2개 이상 60%
    프릴 주방세제 전품목(용기 700ml/리필 1L) 2개 이상 구매시 1개당 각3,800원 
    ※1개 구매시 각9,500원
    ※교차구매 가능
    ※기간 : 1/23(목)~1/26(일)

    2개 이상 50%
    엘라스틴 퍼퓸 샴푸/컨디셔너(각 1,100ml) 2개 이상 구매시 1개당 각6,450원 
    ※1개 구매시 각12,900원 ※라벤더/로즈
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 60%
    케라시스 클린노트&케라틴본드 샴푸/트리트먼트 8종(상품별 상이) 2개 이상 구매시 1개당 각5,160~7,160원
    ※1개 구매시 각12,900~17,900원 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    온더바디 리얼모이스처 바디워시 (각 900g) 2개 이상 구매시 1개당 각4,990원 
    ※1개 구매시 각9,980원
    ※피오니/웜코튼/코코넛향
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    브레프 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,250~4,450원
    ※1개 구매시 각4,500~8,900원
    ※교차구매 가능

    2개 이상 50%
    자연퐁 전품목(상품별 상이) 2개 이상 구매시 1개당 각3,300~9,900원
    ※1개 구매시 각6,600~19,800원
    ※해당상품에 한함 ※교차구매 가능

    롯데/신한/NH농협/삼성카드 1,000원 할인
    잘풀리는집 대용량 키친타월(150매×12롤) 7,900원

    나무야 깨끗한 무향 3겹 화장지(27m×30롤) 9,900원

    리엔 흑모비책 염색약(각 120g×2) 각10,900원

    최대7,970원 할인
    디펜드 언더웨어 12종(상품별 규격 상이) 각9,000~15,930원

    더페이스샵, 비욘드, 이자녹스, 수려한
    해당 브랜드 구매고객 롯데상품권 증정
    ※기간 : 1/23(목)~1/27(월)
    ※도와드리겠습니다 센터 수령
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※점별 입점점/단일 브랜드에 한함

    5만원 이상 구매시 5,000원 롯데상품권 증정! 더페이스샵, 비욘드

    10만원 이상 구매시 10,000원 롯데상품권 증정! 이자녹스, 수려한

    깨끗한 나라 해당상품 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉠ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    순수한면 생리대 8종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,000~7,950원 
    ※1개 구매시 각8,000~15,900원 ※교차구매 가능

    쉬크 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉷ "를 확인하세요
    ※결제시 계산대에서 수령

    쉬크 면도용품 5종(상품별 상이) 각3,900~20,900원

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    40% 테팔 IH 쏘라이트 프라이팬/테팔 IH 티타늄 블라썸 냄비(상품별 상이) 25,680~34,680/26,880~35,880원

    최대 50%
    해피콜 IH 루시드 프라이팬/해피콜 IH 온드 냄비(상품별 상이) 17,450~26,950/26,340~38,340원

    50%
    풍년 마레러스터 IH 압력솥(6인) 52,900원

    포트메리온 보타닉가든(상품별 상이) 롯데/신한/NH농협/삼성카드 최대 50%
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 50%
    글라스락 퓨어오븐(상품별 상이) 3,450~8,450원
    ※기간 : 1/2(목)~1/30(목)

    1,800원 할인
    맥스 폭발방지 CRV부탄가스(4P) 4,990원 ※기간 : 1/9(목)~1/30(목)

    1+1 기획팩
    테이팩스 니트릴 장갑 1+1 기획팩 8,900원

    2개 이상 40%
    크린랩 레이스 고무장갑 2개 이상 구매시 1개당 각1,850~2,150원 ※1개 구매시 각3,090~3,590원 ※교차구매 가능

    최대 4,900원 할인
    오늘좋은 촘촘누비 베개/패드(베개 : 40×60cm/패드 : 140×190cm) 12,000/15,000원
    ※점별 재고 상이 ※해당상품에 한함

    20%
    오늘좋은 클라우드 경추베개(각 2P) 14,320/17,520원 
    ※소 : 40×60cm/대 : 50×70cm
    ※점별 재고 상이 ※해당상품에 한함

    30%
    오늘좋은 고중량 세면타월(각 6P) 각17,430원 ※그레이/라이트브라운/라이트블루
    ※점별 재고 상이 ※해당상품에 한함

    20%
    대림바스 샤워헤드(무광블랙/무광 화이트) 11,920원
    ※입점점에 한함 ※해당상품에 한함

    30%
    룸바이홈 핸드카트 5종(상품별 상이) 18,130~48,930원 ※점별 입점품목 상이

    40%
    아이리스 시스템 수납함(중형 3호/45×35×30cm) 13,740원

    2+1 기획
    10,000원 할인
    명절 선물세트 코코도르 BEST 향 기획 디퓨저(500ml×3개입) 19,900원 ※점별 재고 상이 ※조기 품절될 수 있습니다 ※기간 : 1/16(목)~2/12(수)

    10+10 기획팩
    1,000원 할인
    에너자이저 맥스 기획팩 10+10입(AA/AAA) 각11,900원

    쿡셀 행사상품 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : '24/12/26(목)~'25/1/30(목)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 " ㉪ "를 확인하세요

    20% 쿡셀 프라이팬/냄비(상품별 상이) 31,120~50,240원

    3M 청소/욕실용품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 1/16(목)~1/30(목)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " M "를 확인하세요
    ※결제시 계산대에서 수령

    리필 추가 증정
    3M 중/대형 테이프 클리너 팩/클린 컷 롱핸들 테이프 클리너(대)(상품별 상이) 14,900/19,240/20,780원
    ※해당상품에 한함 ※매장에 더많은 상품이 준비되어 있습니다

    오늘좋은 브랜드 패션잡화 클리어런스
    ※점별 재고수량 상이 ※매장에 더 많은 클리어런스 행사상품이 준비되어 있습니다

    겨울 이불/패브릭 소품 클리어런스(상품별 상이) 각3,000~35,000원 ※해당상품에 한함 ※재고 소진시 자동 종료

    2개 이상 30%
    여성 란제리 클리어런스(각 1입) 2개 이상 구매시 1개당 각4,930~16,730원 ※1개 구매시 각6,900~23,900원 ※교차구매 가능

    방한 패션잡화클리어런스(장갑/모자/가방/머플러/넥워머 등) 각3,000~15,000원

    네파/MLB/JEEP 방한 슬리퍼/샌들 각10,000원 ※행사상품에 한함 ※점별 재고 상이

    오늘좋은 겨울 거실화(상품별 상이) 각5,000/7,000원
    ※점별 재고 상이 ※해당상품에 한함 ※재고 소진시 조기종료

    인기 고양이 사료&간식 30%

    30%
    템테이션 8종&쉬바 캔/파우치 20종(상품별 상이) 1,260~3,010원 ※입점점 상이

    HAPPY ToysRus Day

    레고 쟌의 울트라 합체 로봇 외 147종(상품별 상이) 5,900~279,900원 ※입점점에 한함

    슈팅스타 티니핑 슈팅스타팩트(3세 이상/26×8×28cm) 93,900원
    ※입점점에 한함 ※점별 재고 상이

    L.POINT 20% -> 롯데/신한/NH농협/삼성카드 10% 추가할인
    실바니안 행복한 꿈의 궁전(5537)(3세 이상/38×18×35cm) 53,928원
    ※입점점에 한함
    ※점별 재고 상이 ※페이지 하단 카드할인 세부내용 참고

    L.POINT 20% -> 롯데/신한/NH농협/삼성카드 10% 추가할인
    헬로카봇 스타가디언(37개월 이상/59×13.5×42cm) 126,720원
    ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

    20% 타요 천하장사 중장비 디럭스 놀이세트(37개월 이상/35×19×34cm) 44,800원 ※입점점에 한함

    11,000원 할인 아트샌드 디럭스 세트(3세 이상/45×7.5×38cm) 43,900원 ※입점점에 한함

    20% 그래비트랙스 코어 스타터 : 밸런스 외(상품별 상이) 135,120원 ※입점점에 한함

    스위치 동키콩 리턴즈 HD(전체 이용가) 64,800원

    동키콩 리턴즈 HD 구매시 에코백 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1개)
    ※소진시 증정행사 자동종료
    ※구분자 " D "를 확인하세요

    롯데마트&MAXX카드 롯데마트맥스&신한카드 롯데카드 비씨카드 KB국민카드 신한카드 NH농협카드 하나카드 삼성카드 우리카드 IBK기업은행 JB카드 KJ카드
    최대 50만원 상품권 증정 또는 즉시할인
    최대 30% 할인 혜택 
    L.POINT 회원 특별할인 ※해당상품에 한함
    구매 수량에 따라 덤 증정 하나 더 ※해당상품에 한함

    ▶기간 : 1/16(목) ~ 1/29(수) ※상품권 증정 프로모션은 변경될 수 있습니다 ※상품권 증정 혜택과 중복 제공 불가

    ※정상가란? 2025년 1월 2일(목) ~ 1월 22일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 12월 26일(목) ~ 2025년 1월 15일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202501_5";
const category = [];

const data = {
  L501: {
    title: "스마트전단지-전점501",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L503: {
    title: "스마트전단지-제타플렉스 잠실점503",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L505: {
    title: "스마트전단지-제타플렉스 서울역점505",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
