import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import queryString from "query-string";

import axios from "axios";

import { leafletState } from "./constants";

import fixedLeaflet from "./data/fixedLeaflet";
import superLeaflet from "./data/super";
import vicMaxx from "./data/vicMaxx";
import thisWeek from "./data/202501_5";
import willThisWeek from "./data/202502_1";

import { gaPageView } from "../../utils/ga";
import LeafletItemPaper from "./components/LeafletItemPaper";

function Leaflet() {
  const location = useLocation();
  const from = queryString.parse(location.search).from;

  const leafletType =
    queryString.parse(location.search).type === "SUPER" ? "SUPER" : "MART";

  const leafletTypeIndex = ["HYPER", "ZETTA", "MAXX"].findIndex(
    (type) => type === queryString.parse(location.search).type
  );
  const leafletNum = Number(location.pathname.split("/")[1]);

  const [leafletInfo, setLeafletInfo] = useState();
  const [imageInfoList, setImageInfoList] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [leafletIndex, setLeafletIndex] = useState([]);

  const getLeafletInfo = useCallback(() => {
    //고정전단 합치기
    const sumLeaflet = {
      ...fixedLeaflet,
      ...superLeaflet,
      ...vicMaxx,
      ...thisWeek,
      ...willThisWeek,
    };

    // 전단 데이터 추출
    let leafletData = "";

    if (leafletIndex.indexOf(leafletNum) < 0) {
      //진행중인 전단이 아닌 경우 각 타입의 첫번째 전단
      leafletData =
        sumLeaflet[
          `L${leafletIndex[leafletTypeIndex > -1 ? leafletTypeIndex : 0]}`
        ];
    } else {
      leafletData = sumLeaflet[`L${leafletNum}`];
    }

    if (leafletData) {
      setLeafletInfo({
        imgPath: leafletData.imgPath,
        title: leafletData.title,
        type: leafletData.type,
        leafletType: leafletData.leafletType || "",
        isFooter: leafletData.isFooter || false,
        isCoupon: leafletData.isCoupon || false,
        category: leafletData.category || [],
      });
      setImageInfoList(leafletData.contents);
    }
  }, [leafletIndex, leafletNum, leafletTypeIndex]);

  //전단 노출 시간 체크
  const getCrntTimeCheck = useCallback(() => {
    // 새로운 전단지 작업시 reload 되는 걸 막기 위해
    const _leafletStateThisWeek = leafletState[leafletType].thisWeek.concat(
      leafletState[leafletType].willThisWeek
    );

    axios
      .post(
        `${process.env.REACT_APP_API}/fo/user/common/current-time`,
        {},
        {
          headers: {
            "x-ssp-channel": "1",
            "Content-Type": "application/json; charset=utf-8",
            "x-ssp-gateway-service-id": "USER",
          },
        }
      )
      .then((res) => {
        const crntTime = res.data.data.current_time.substr(0, 8);

        const leafletIndex =
          crntTime - leafletState[leafletType].openDate >= 0
            ? leafletState[leafletType].willThisWeek
            : _leafletStateThisWeek;

        setLeafletIndex(leafletIndex);
      })
      .then(() => {
        setIsSuccess(true);
        window.scrollTo(0, 0);
      });
  }, [leafletType]);

  useEffect(() => {
    getCrntTimeCheck();
  }, [getCrntTimeCheck]);

  useEffect(() => {
    if (leafletIndex.length < 1) return;
    getLeafletInfo();
  }, [getLeafletInfo, leafletIndex.length]);

  useEffect(() => {
    if (!isSuccess) return;
    if (!leafletInfo) return;

    console.log(leafletInfo.title);
    gaPageView(`${leafletInfo.title}${from ? `(${from})` : "()"}`, "");

    // LMS GA 저장 후 쿼리스트링 삭제
    const fromCheck =
      location.search.indexOf("?from=") > -1
        ? `?from=${from}`
        : from
        ? `&from=${from}`
        : "";

    const searchReplaceFrom = fromCheck
      ? location.search.replace(fromCheck, "")
      : location.search;

    window.history.replaceState(
      null,
      "",
      `${location.pathname}${searchReplaceFrom}`
    );
  }, [from, isSuccess, leafletInfo, location.pathname, location.search]);

  //viewport mata 확대 설정
  const viewportSetting =
    "width=device-width, initial-scale=1, maximum-scale=5.0, user-scalable=yes";

  return (
    <>
      {isSuccess && leafletInfo && (
        <>
          <Helmet>
            <title>롯데마트 롯데슈퍼 전단</title>
            <meta name="viewport" content={viewportSetting} />
          </Helmet>

          <LeafletItemPaper
            imageInfoList={imageInfoList}
            leafletInfo={leafletInfo}
          />
        </>
      )}
    </>
  );
}

export default Leaflet;
