import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2025. 1. 31(금) ~ 2025. 2. 12(수) < 신선식품 적용기간 : 2025. 1. 31(금) ~ 2. 5(수) >

    롯데마트 맛 좋은 신선과 간편식 추천!

    명절 끝! 집밥 솔루션

    한우 구이류(1등급)(각 100g/냉장/국내산 한우고기) L.POINT 30% 
    ※조기 품절될 수 있습니다

    딸기 전품목(상품별 상이/국산) 2개 이상 구매시 각2,000원 할인 
    ※교차구매 가능 ※점별 운영상품 상이

    L.POINT 35%
    남해안 생굴(100g/냉장/국산) 1,918원

    큰초밥(20입)(팩/원산지 별도표기) 15,990원

    숯불 무뼈 닭발(팩/원산지 별도표기) 11,900원
    ※족발매장 입점점에 한함 

    2개 이상 10%
    청정원 호밍스 냉동 밀키트 8종(상품별 규격 상이) 2개 이상 구매시 1개당 각9,900~13,950원
    ※1개 구매시 각 11,000~15,500원
    ※부산식 곱창전골 外 7종 ※교차구매 가능

    2개 이상 각1,980원 할인
    풀무원 생 수제비/우동 5종(상품별 상이) 2개 이상 구매시 1개당 각5,000원 
    ※1개 구매시 각 6,980원 ※교차구매 가능

    3개 이상 각 1,490원 할인
    오뚜기 맛있는 떡볶이 3종(상품별 상이) 3개 이상 구매시 1개당 각3,300원 
    ※1개 구매시 각 4,790원 ※교차구매 가능

    1+1 
    동원 바른어묵 전골용(350g) 4,480원

    최대 50%
    제로 대용량 탄산음료 15종(상품별 행사 상이/1.2L~1.8L) 1,290~2,740원

    세계맥주 골라담기(상품별 상이) 5캔 구매시 12,900원
    ※4캔 구매시 9,600원
    ※1캔 구매시 3,000원

    롯데마트 GO앱 특가!
    ▶기간 : 1/31(금) ~ 2/5(수)
    ※롯데마트GO앱 스캔시 행사가 적용됩니다

    GO앱 스캔시 20%
    프리미엄 장어초밥(8입/냉장/원산지 별도표기) 9,920원

    GO앱 스캔시 6,000원 할인
    동원 프리미엄 명란 기획(100g×2/냉장/원산지 별도표기) 10,900원

    GO앱 스캔시 10,000원 할인
    아임비타 마그네슘샷(25ml×10병) 19,900원

    2025 롯데마트 물가잡기 캠페인 매주 꼭 필요한 상품을 THE HOT하게 제안합니다.

    이번주 핫프라이스

    스테비아 대추방울토마토 전품목(500g/팩/국산) 2팩 이상 구매시 각2,000원 할인 
    ※교차구매 가능
    ※점별 운영상품 상이
    ※조기 품절될 수 있습니다

    롯데/신한/NH농협/삼성카드 1,000원 할인
    대파(700g/봉/국산) 1,990원
    ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/NH농협/삼성카드 2개 이상 55%
    크리넥스 순수소프트 3겹 화장지(35m×30롤) 해당카드로 2개 이상 구매시 1개당 각17,500원
    ※1개 구매시 38,900원
    ※기간 : 1/31(금) ~ 2/5(수) ※페이지 하단 카드할인 세부내용 참고

    이번주 핫PB

    요리하다 소고기라면(5개입) 2,990원

    1,000원 할인
    요리하다 얼큰한 김치우동 2인(432g) 2,990원

    1,000원 할인
    요리하다 칠리새우(400g) 6,990원

    3개 이상 20%, 2개 이상 10%
    오늘좋은 밀크/다크 초콜릿(각 100g) 3개 이상 구매시 1개당 각1,600원 
    ※2개 구매시 1개당 각 1,800원
    ※1개 구매시 각 2,000원 ※교차구매 가능

    공구핫딜

    공구핫딜 한성 와일드 크래미(180g×3입) 6,980원
    
    칠성사이다(190ml×30입) 14,490원

    롯데칠성 델몬트 팜앤홈 제주감귤(1.2L×4입) 5,880원

    오늘좋은 공구핫딜 베이직 거실화 2P(그레이/네이비) 각9,900원

    ※정상가란? 2025년 1월 10일(금) ~ 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `<div>
    미국산/호주산 냉동 간편구이 모음(품목별 상이/냉동/원산지 별도표기) L.POINT 최대 40%

    국내산 돼지고기 삼겹살/목심(각 100g/냉장/국내산) 각2,390원
    ※제주점 제외
    ※조기 품절될 수 있습니다

    L.POINT 50%
    요리하다 흑돼지 제육볶음(800g/냉장/원산지 별도표기) 9,900원

    L.POINT 50%
    달구벌 소대창/소곱창(소금구이)(400g/300g, 냉동, 원산지 별도표기) 각8,950원

    1+1
    노랑오리 마늘치킨 훈제슬라이스(400g/냉장/원산지 별도표기) 9,990원

    L.POINT 40%
    ASC 냉동 새우살 4종(400g/냉동/원산지 별도표기) 13,140원

    L.POINT 10%
    손질 오징어(3마리/해동/원양산) 9,990원

    L.POINT 2,000원
    천년 목사골 홍어(모둠)(150g/냉장/아르헨티나산) 9,900원

    동원 양반 들기름향이 그윽한 식탁김(4.5g×20봉/원산지 별도표기) 7,980원

    풀무원 동물복지 구운란(20입/원산지 별도표기) 9,990원

    샐러리(봉/국산)/비트(1kg/봉/국산)/케일(봉/국산) 각3,990원

    데일리 샐러드(300g/국산) 2,990원

    2봉 이상 각 2,000원 할인
    제주 한라봉/천혜향/레드향(각 4~7입/봉/국산) 2봉 이상 구매시 1봉당 각11,990원 ※1봉 구매시 각13,990원

    2팩 이상 각 2,000원 할인
    칠레산 블루베리(310g/팩/칠레산) 2팩 이상 구매시 1팩당 각5,990원 ※1팩 구매시 7,990원

    스위티오/감숙왕 바나나(1.3kg 내외/송이/필리핀산) 3,990원

    L.POINT 35% 
    갱엿 닭강정(대)(1팩/원산지 별도표기) 11,044원

    한달내내 +50% 중량 UP "가격은 그대로"
    온가족 한통가득 탕수육(1팩/원산지 별도표기) 12,990원

    김밥&유부&롤 한판세트(1팩/원산지 별도표기) 10,990원

    L.POINT 20%
    바비큐 대왕통다리(1팩/원산지 별도표기) 7,992원 

    2,000원 할인
    한겹씩 뜯어먹는 티슈브레드 2입(1봉/원산지 별도표기) 7,900원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    하나사면 하나 더 1+1

    1+1
    동원 가쓰오 크랩스(144g) 3,980원

    1+1
    CJ 비비고 떡볶이 3종(각 360g, 오리지널/매운/치즈) 각5,480원
    ※교차구매 가능

    1+1
    CJ 비비고 곱창전골(800g)/곱창순대전골(935g) 15,980/17,980원
    ※기간 : 1/31(금)~2/2(일) ※동일 상품에 한함

    1+1
    종가 맛김치(800g) 14,900원

    1+1
    하림 더미식 교자 2종(각 350g×2입)/춘천 닭갈비 볶음밥(210g×2입) 7,980/10,980원
    ※기간 : 1/31(금)~2/2(일) ※동일 가격 간 교차구매 가능

    1+1
    롯데쉐푸드 미니돈까스(380g) 6,980원

    1+1
    씨제이 햇반 곤약밥 2종(각 150g×3입, 귀리흑미/현미귀리) 각8,980원
    ※교차구매 가능

    1+1
    다담 파우치양념 5종(상품별 규격 상이) 각2,980원 ※교차구매 가능

    1+1
    해찬들 원조 태양초 고추장(1.5kg) 19,000원

    1+1
    빙그레 끼리 크림치즈 그릭스타일(200g) 8,000원

    1+1
    파워에이드 1.5L 外 스포츠음료 8종(상품별 상이) 1,980~3,980원 ※동일 브랜드간 교차구매가능

    1+1
    달고나 쿠키/허쉬 초콜릿칩쿠키/허쉬 초콜릿칩 베리쿠키(각 144g) 각5,290원 ※교차구매 가능

    L.POINT 1+1
    삼립 상쾌한아침 우유식빵/삼립 미니 꿀호떡 2종(상품별 규격 상이) 각3,480원
    ※오리지널/옥수수※교차구매 가능

    1+1
    베지밀 검은콩 고칼슘두유(파우치)(190ml×15입) 18,750원

    1+1
    대용량 과채주스 27종(1L~1.8L) 3,630~14,900원 ※동일 브랜드 내 동일가격 간 교차구매가능

    1+1
    녹차원 꿀 유자차 外 5종(각 1kg) 각9,900원 ※교차구매 가능

    1+1
    녹차원 생강차/쌍화차(각 15g×30입) 각9,900원 ※교차구매 가능

    2개 이상 구매시 더 싸게

    2개 이상 최대4,480원 할인
    CJ 육공육 4종(상품별 상이) 2개 이상 구매시 1개당 각5,000원 
    ※1개 구매시 각5,580~9,480원 ※교차구매 가능

    2개 이상 20% 
    CJ 비비고 순살 구이(삼치/고등어, 상품별 상이) 2개 이상 구매시 1개당 각4,192원 
    ※1개 구매시 각5,240원 ※교차구매 가능

    2개 이상 25% 
    하남돼지집 매콤 콩나물 불고기 外 3종(상품별 상이) 2개 이상 구매시 1개당 각7,425~11,175원
    ※1개 구매시 각9,900~14,900원 ※교차구매 가능

    2개 이상 50%
    풀무원 노엣지 피자 3종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,990원 ※1개 구매시 각9,980원
    ※교차구매 가능 ※코리안BBQ/포테이토&콘/토마토&미트콤보

    2개 이상 각1,000원 할인
    CJ 고메 소바바 치킨 5종(양념순살 外 4종, 상품별 규격 상이) 2개 이상 구매시 1개당 각7,980원
    ※1개 구매시 각8,980원 ※교차구매 가능

    2개 이상 20% 
    오뚜기 마열라면(4개입) 2개 이상 구매시 1개당 각3,584원 ※1개 구매시 4,480원

    2개 이상 40% 
    씨제이 햇반 솥반 5종(상품별 규격 상이) 2개 이상 구매시 1개당 각2,988원 
    ※1개 구매시 각4,980원 ※교차구매 가능

    2개 이상 50% 
    샘표 고추장/된장/쌈장 전품목 15종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,245~16,490원
    ※1개 구매시 각2,490~32,980원
    ※교차구매 가능 ※점별 운영상품 상이

    2개 이상 50% 
    남양 불가리스 제로 3종(각 80g×4입) 2개 이상 구매시 1개당 각1,890원 
    ※1개 구매시 각3,780원 ※교차구매 가능 ※딸기/복숭아/플레인

    2개 이상 50% 
    일동후디스 그릭요거트 3종(각 80g×4입) 2개 이상 구매시 1개당 각3,200원 
    ※1개 구매시 각6,400원 ※교차구매 가능 ※플레인/저지방/코코포도

    2개 이상 10% 
    크라운 스낵 5종(상품별 상이) 2개 이상 구매시 1개당 각1,782원 ※1개 구매시 각1,980원 ※교차구매 가능

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    발렌타인데이 

    해당상품 롯데/신한/NH농협/삼성카드 1만 8천원 이상 결제시 5,000원 롯데상품권 증정
    ※해당 브랜드(8개사) : 롯데웰푸드/매일유업/농심/한국마즈/해태제과/크라운제과/오리온/삼경(하리보 제외)
    ※페이지 하단 카드할인 세부내용 참고 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " V "를 확인하세요 ※결제시 계산대에서 수령

    10%
    매일 페레로로쉐 T8 하트(100g)/T24 다이아(300g) 8,820/22,590원

    롯데 프리미엄 가나 레몬 치즈 타르트/베리무스케익(각 68g) 각3,490원

    하리보 스위트박스(600g) 9,980원 ※상품권 증정 제외

    부샤드 나폴리탄 카라멜&씨솔트 초콜릿 틴 기획(180g) 15,980원 ※상품권 증정 제외

    목우촌 프라임 소시지 2종(상품별 상이) 각6,980원 ※기간 : 2/1(토)~2/28(금)

    풀무원 얇은피 꽉찬속 김치/고기만두(각 800g) 각8,980원

    사조대림 실속김밥 세트(510g) 7,980원 ※기간 : 2/1(토)~2/28(금)

    3,000원 할인
    사세 치킨 가라아게(500g) 6,980원

    2,500원 할인
    풀무원 체다모짜 핫도그(320g)/탱글뽀득 핫도그(400g) 각6,480원

    L.POINT 45%
    풀무원 식물성 지구식단 로스팅 정면(4개입) 2,998원

    L.POINT 6,970원 
    백설 고소함 가득 참기름(500ml) 7,980원

    서울 흰우유(2.3L) 6,280원

    각700원 할인
    서울 비요뜨 4종(상품별 상이) 각2,180원
    ※링크/초코링/크런치볼/쿠키앤크림
    
    아임비타 해당상품 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ■ "를 확인하세요
    ※결제시 계산대에서 수령

    2+1 아임비타 이뮨플러스(23.5g×7입) 16,900원

    2개 이상 34% 락토핏 골드/코어/슬림(상품별 상이) 2개 이상 구매시 1개당 각10,494~17,754원 
    ※1개 구매시 각15,900~26,900원 ※교차구매 가능

    남양 드빈치 자연방목 피자치즈(1kg) 13,980원

    소와나무 이태리안 피자치즈(280g) 6,280원

    각600원 할인
    아침에주스 오렌지/포도/사과(각 1.8L) 각5,980원

    인기 생필품 득템 찬스

    1+1
    히말라야 핑크솔트 바디워시 3종(각 900g) 각12,980원
    ※민트/로즈/포레스트 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    엘라스틴 아미노 프로틴 클리닉 샴푸/컨디셔너(각 855ml) 2개 이상 구매시 1개당 각6,450원 
    ※1개 구매시 각12,900원 ※샴푸 : 손상/볼륨 ※컨디셔너 : 손상
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 각2,500원 할인
    닥터그루트 집중케어 샴푸 기획(각 샴푸 400ml+50ml+컨디셔너 50ml) 2개 이상 구매시 1개당 각20,500원
    ※1개 구매시 각23,000원 ※힘없는 모발용/가려운 두피용 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    갸스비 왁스 4종&스프레이 3종(상품별 상이) 2개 이상 구매시 1개당 각4,545~6,000원 
    ※1개 구매시 각9,090~12,000원 ※점별 운영상품 상이 ※교차구매 가능

    1+1
    센소다인 딥클린 칫솔/젠틀칫솔(각 4입) 각10,900원 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 55%
    해피바스 클렌징 폼 3종(각 200g) 2개 이상 구매시 1개당 각4,005원 ※1개 구매시 각8,900원
    ※어성초/라이스/대나무숯 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    2080 파워쉴드 플러스 치약(블루)(120g×3입) 2개 이상 구매시 1개당 각4,950원 
    ※1개 구매시 9,900원

    2개 이상 50%
    잘풀리는집 키친타월(150매×4입) 2개 이상 구매시 1개당 각2,950원 ※1개 구매시 5,900원

    카밀 핸드크림 미니기획(30ml×3입) 6,900원 ※점별 재고 상이

    우르오스 스칼프 샴푸(500ml)/스킨 밀크, 스킨로션(각 200ml) 각23,500원 ※점별 운영상품 상이

    2개 이상 50%
    테크/피지 액체세제 11종(상품별 상이) 2개 이상 구매시 1개당 각7,100~14,950원
    ※1개 구매시 각14,200~29,900원
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    프릴 애플 주방세제(용기 700ml/리필 1L) 2개 이상 구매시 1개당 각4,750원 
    ※1개 구매시 각9,500원 ※교차구매 가능

    2개 이상 50%
    홈스타 맥스프레쉬 4종(각 900ml) 2개 이상 구매시 1개당 각3,950원 
    ※1개 구매시 각7,900원 ※교차구매 가능

    피지오겔 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " B "를 확인하세요
    ※결제시 계산대에서 수령

    피지오겔 DMT 로션(200ml)/크림(150ml) 21,510/26,910원 ※점별 운영상품 상이

    쏘피 생리대 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    쏘피 한결 생리대(팬티라이너/중형, 대형, 슈퍼롱) 2개 이상 구매시 1개당 각4,250/5,950원 
    ※1개 구매시 각8,500/11,900원 ※교차구매 가능

    헨켈 라벤더 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉭ "를 확인하세요
    ※결제시 계산대에서 수령

    최대50%
    헨켈(퍼실/버넬/브레프)라벤더 대전(상품별 상이) 각2,250~19,900원

    하기스 기저귀 전품목 10만원 이상 구매시 10,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉻ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    하기스 기저귀(상품별 상이) 2개 이상 구매시 1개당 각15,300~22,950원 
    ※1개 구매시 각30,600~45,900원 ※교차구매 가능

    피죤 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉬ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    피죤(액츠/피죤/무균무때)브랜드 대전(상품별 상이) 2개 이상 구매시 1개당 각3,450~9,950원 
    ※1개 구매시 각6,900~19,900원 ※브랜드별 교차구매 가능

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    인기 와인/위스키 해당상품 L.POINT 최대 7,400원 할인

    L.POINT 5,000원
    더 롱 독 루즈/블랑(각 750ml) 각11,900원

    L.POINT 7,400원
    엠페라도르 디럭스 스페셜 리저브(700ml) 9,900원

    6캔 구매시 10%
    국민맥주 3종(각 500ml, 라거/에일/바이젠) 6캔 구매시 1캔당 각1,620~1,800원 
    ※1캔 구매시 각1,800~2,000

    별빛 청하 변온잔 패키지(295ml×4) 7,960원

    L.POINT 40%
    해피콜 뉴골든구르메 IH 프라이팬/냄비(상품별 상이) 20,940~43,140원

    브랜드 IH 프라이팬 3종(각 28cm) 각20,900원
    ※테팔/해피콜/도루코 인덕션 프라이팬 3종
    ※기간 : 1/31(금)~2/26(수)

    최대 40%
    레녹스/프라카 식기(상품별 상이) 각4,830~21,540원 

    1+1 기획팩
    유니랩 위생롤백/장갑/지퍼백(상품별 상이) 각3,480~5,980원

    1+1 기획팩
    3M 황토숯 고무장갑/다목적 수세미(소 3입) 각3,190~4,380원

    20%
    썬 부탄가스(4입) 5,430원

    2개 이상 50%
    네오플램 퓨어락(상품별 상이) 2개 이상 구매시 1개당 각2,200~6,200원
    ※1개 구매시 각4,400~12,400원 ※교차구매 가능

    오늘좋은 냉동밥 용기(8P) 4,900원

    20%
    오늘좋은 하트쿠션&과자 바디필로우(상품별 상이) 7,920~23,920원
    ※점별 재고 상이 ※해당상품에 한함
    ※재고 소진시 조기 종료

    20%
    사각사각 호텔식 워싱차렵이불/오늘좋은 호텔베개(상품별 상이) 23,920~37,520원
    ※이불 - S : 150×200cm/Q : 180×210cm
    ※베개 - 40×60cm/50×70cm ※점별 재고 상이
    ※해당상품에 한함 ※재고 소진시 조기 종료

    30%
    오늘좋은 아로마 세면타월(각 4입) 각13,930원
    ※아쿠아/블라썸/유칼립투스/라벤더
    ※입점점에 한함 ※해당상품에 한함

    아쿠아듀오 블라썸 필터샤워 세트(염소제거용)(헤드+리필 2입) 각15,900원 ※버터/그레이
    ※입점점에 한함 ※점별 입점상품 상이 ※해당상품에 한함

    20%
    오늘좋은 포켓형 장바구니 2종 2,000원

    최대 30%
    자동차용품 클리어런스(상품별 상이) 각1,840~66,320원

    20% 코알라하트호야/코코도르 에프터눈티 디퓨저 7,920/9,520원

    방한 패션잡화클리어런스(장갑/모자/가방/머플러/넥워머 등) 각2,000~13,000원

    2개 이상 50%
    하우홈 보온/단열시트 9종 2개 이상 구매시 1개당 각990~8,450원 ※1개 구매시 각1,990~16,900원 ※교차구매 가능

    2개 이상 30%
    브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각7,920~8,720원 
    ※1개 구매시 각6,930~7,630원 ※교차구매 가능
    ※뉴발란스, 언더아머, 휠라, 네파, MLB 등 ※점별 상품 상이

    라임/TRY 브랜드 잠옷(상품별 상이) 각11,940~26,940원 ※기간 : 1/23(목)~2/5(수)

    오늘좋은 겨울 침구/담요/거실화 外(상품별 상이) 각3,000~35,000원
    ※입점점에 한함 ※해당상품에 한함
    ※최초 판매가 기준 ※소진시 종료 ※점별 재고 상이
    ※매장에 더많은 상품이 준비되어 있습니다

    오늘좋은 히트 언더웨어(1입) 각5,900~9,900원

    오늘좋은 수면/밍크스판 홈웨어(바지/원피스/세트) 각6,900~12,900원

    산리오 外 아동 캐릭터 실내복(상품별 상이) 각6,900~21,900원

    ToysRus 디즈니 캐릭터 눈집게 3,900원

    2,400원 할인 디즈니 눈집게 3종(34.3×10.6×8.4cm) 3,900원

    colioli 반려동물 인기 식/용품 20%

    20%
    콜리올리 도톰한 3~4회용 패드 3종(M 80매/L 30매/XL 20매) 각11,120~16,720원

    20%
    콜리올리 스크래쳐 4종(상품별 상이) 각3,600~12,720원

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2025. 1. 31(금) ~ 2025. 2. 12(수) < 신선식품 적용기간 : 2025. 1. 31(금) ~ 2. 5(수) >
    ·제타플렉스 잠실점

    롯데마트 맛 좋은 신선과 간편식 추천!

    명절 끝! 집밥 솔루션

    한우 구이류(1등급)(각 100g/냉장/국내산 한우고기) L.POINT 30% 
    ※조기 품절될 수 있습니다

    딸기 전품목(상품별 상이/국산) 2개 이상 구매시 각2,000원 할인 
    ※교차구매 가능 ※점별 운영상품 상이

    L.POINT 35%
    남해안 생굴(100g/냉장/국산) 1,918원

    큰초밥(20입)(팩/원산지 별도표기) 15,990원

    숯불 무뼈 닭발(팩/원산지 별도표기) 11,900원
    ※족발매장 입점점에 한함 

    2개 이상 10%
    청정원 호밍스 냉동 밀키트 8종(상품별 규격 상이) 2개 이상 구매시 1개당 각9,900~13,950원
    ※1개 구매시 각 11,000~15,500원
    ※부산식 곱창전골 外 7종 ※교차구매 가능

    2개 이상 각1,980원 할인
    풀무원 생 수제비/우동 5종(상품별 상이) 2개 이상 구매시 1개당 각5,000원 
    ※1개 구매시 각 6,980원 ※교차구매 가능

    3개 이상 각 1,490원 할인
    오뚜기 맛있는 떡볶이 3종(상품별 상이) 3개 이상 구매시 1개당 각3,300원 
    ※1개 구매시 각 4,790원 ※교차구매 가능

    1+1 
    동원 바른어묵 전골용(350g) 4,480원

    최대 50%
    제로 대용량 탄산음료 15종(상품별 행사 상이/1.2L~1.8L) 1,290~2,740원

    세계맥주 골라담기(상품별 상이) 5캔 구매시 12,900원
    ※4캔 구매시 9,600원
    ※1캔 구매시 3,000원

    롯데마트 GO앱 특가!
    ▶기간 : 1/31(금) ~ 2/5(수)
    ※롯데마트GO앱 스캔시 행사가 적용됩니다

    GO앱 스캔시 20%
    프리미엄 장어초밥(8입/냉장/원산지 별도표기) 9,920원

    GO앱 스캔시 6,000원 할인
    동원 프리미엄 명란 기획(100g×2/냉장/원산지 별도표기) 10,900원

    GO앱 스캔시 10,000원 할인
    아임비타 마그네슘샷(25ml×10병) 19,900원

    2025 롯데마트 물가잡기 캠페인 매주 꼭 필요한 상품을 THE HOT하게 제안합니다.

    이번주 핫프라이스

    스테비아 대추방울토마토 전품목(500g/팩/국산) 2팩 이상 구매시 각2,000원 할인 
    ※교차구매 가능
    ※점별 운영상품 상이
    ※조기 품절될 수 있습니다

    롯데/신한/NH농협/삼성카드 1,000원 할인
    대파(700g/봉/국산) 1,990원
    ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/NH농협/삼성카드 2개 이상 55%
    크리넥스 순수소프트 3겹 화장지(35m×30롤) 해당카드로 2개 이상 구매시 1개당 각17,500원
    ※1개 구매시 38,900원
    ※기간 : 1/31(금) ~ 2/5(수) ※페이지 하단 카드할인 세부내용 참고

    이번주 핫PB

    요리하다 소고기라면(5개입) 2,990원

    1,000원 할인
    요리하다 얼큰한 김치우동 2인(432g) 2,990원

    1,000원 할인
    요리하다 칠리새우(400g) 6,990원

    3개 이상 20%, 2개 이상 10%
    오늘좋은 밀크/다크 초콜릿(각 100g) 3개 이상 구매시 1개당 각1,600원 
    ※2개 구매시 1개당 각 1,800원
    ※1개 구매시 각 2,000원 ※교차구매 가능

    공구핫딜

    공구핫딜 한성 와일드 크래미(180g×3입) 6,980원
    
    칠성사이다(190ml×30입) 14,490원

    롯데칠성 델몬트 팜앤홈 제주감귤(1.2L×4입) 5,880원

    오늘좋은 공구핫딜 베이직 거실화 2P(그레이/네이비) 각9,900원

    ※정상가란? 2025년 1월 10일(금) ~ 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    미국산/호주산 냉동 간편구이 모음(품목별 상이/냉동/원산지 별도표기) L.POINT 최대 40%

    국내산 돼지고기 삼겹살/목심(각 100g/냉장/국내산) 각2,390원
    ※제주점 제외
    ※조기 품절될 수 있습니다

    L.POINT 50%
    요리하다 흑돼지 제육볶음(800g/냉장/원산지 별도표기) 9,900원

    L.POINT 50%
    달구벌 소대창/소곱창(소금구이)(400g/300g, 냉동, 원산지 별도표기) 각8,950원

    1+1
    노랑오리 마늘치킨 훈제슬라이스(400g/냉장/원산지 별도표기) 9,990원

    L.POINT 40%
    ASC 냉동 새우살 4종(400g/냉동/원산지 별도표기) 13,140원

    L.POINT 10%
    손질 오징어(3마리/해동/원양산) 9,990원

    L.POINT 2,000원
    천년 목사골 홍어(모둠)(150g/냉장/아르헨티나산) 9,900원

    동원 양반 들기름향이 그윽한 식탁김(4.5g×20봉/원산지 별도표기) 7,980원

    풀무원 동물복지 구운란(20입/원산지 별도표기) 9,990원

    샐러리(봉/국산)/비트(1kg/봉/국산)/케일(봉/국산) 각3,990원

    데일리 샐러드(300g/국산) 2,990원

    2봉 이상 각 2,000원 할인
    제주 한라봉/천혜향/레드향(각 4~7입/봉/국산) 2봉 이상 구매시 1봉당 각11,990원 ※1봉 구매시 각13,990원

    2팩 이상 각 2,000원 할인
    칠레산 블루베리(310g/팩/칠레산) 2팩 이상 구매시 1팩당 각5,990원 ※1팩 구매시 7,990원

    스위티오/감숙왕 바나나(1.3kg 내외/송이/필리핀산) 3,990원

    L.POINT 35% 
    갱엿 닭강정(대)(1팩/원산지 별도표기) 11,044원

    한달내내 +50% 중량 UP "가격은 그대로"
    온가족 한통가득 탕수육(1팩/원산지 별도표기) 12,990원

    김밥&유부&롤 한판세트(1팩/원산지 별도표기) 10,990원

    L.POINT 20%
    바비큐 대왕통다리(1팩/원산지 별도표기) 7,992원 

    2,000원 할인
    한겹씩 뜯어먹는 티슈브레드 2입(1봉/원산지 별도표기) 7,900원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    하나사면 하나 더 1+1

    1+1
    동원 가쓰오 크랩스(144g) 3,980원

    1+1
    CJ 비비고 떡볶이 3종(각 360g, 오리지널/매운/치즈) 각5,480원
    ※교차구매 가능

    1+1
    CJ 비비고 곱창전골(800g)/곱창순대전골(935g) 15,980/17,980원
    ※기간 : 1/31(금)~2/2(일) ※동일 상품에 한함

    1+1
    종가 맛김치(800g) 14,900원

    1+1
    하림 더미식 교자 2종(각 350g×2입)/춘천 닭갈비 볶음밥(210g×2입) 7,980/10,980원
    ※기간 : 1/31(금)~2/2(일) ※동일 가격 간 교차구매 가능

    1+1
    롯데쉐푸드 미니돈까스(380g) 6,980원

    1+1
    씨제이 햇반 곤약밥 2종(각 150g×3입, 귀리흑미/현미귀리) 각8,980원
    ※교차구매 가능

    1+1
    다담 파우치양념 5종(상품별 규격 상이) 각2,980원 ※교차구매 가능

    1+1
    해찬들 원조 태양초 고추장(1.5kg) 19,000원

    1+1
    빙그레 끼리 크림치즈 그릭스타일(200g) 8,000원

    1+1
    파워에이드 1.5L 外 스포츠음료 8종(상품별 상이) 1,980~3,980원 ※동일 브랜드간 교차구매가능

    1+1
    달고나 쿠키/허쉬 초콜릿칩쿠키/허쉬 초콜릿칩 베리쿠키(각 144g) 각5,290원 ※교차구매 가능

    L.POINT 1+1
    삼립 상쾌한아침 우유식빵/삼립 미니 꿀호떡 2종(상품별 규격 상이) 각3,480원
    ※오리지널/옥수수※교차구매 가능

    1+1
    베지밀 검은콩 고칼슘두유(파우치)(190ml×15입) 18,750원

    1+1
    대용량 과채주스 27종(1L~1.8L) 3,630~14,900원 ※동일 브랜드 내 동일가격 간 교차구매가능

    1+1
    녹차원 꿀 유자차 外 5종(각 1kg) 각9,900원 ※교차구매 가능

    1+1
    녹차원 생강차/쌍화차(각 15g×30입) 각9,900원 ※교차구매 가능

    2개 이상 구매시 더 싸게

    2개 이상 최대4,480원 할인
    CJ 육공육 4종(상품별 상이) 2개 이상 구매시 1개당 각5,000원 
    ※1개 구매시 각5,580~9,480원 ※교차구매 가능

    2개 이상 20% 
    CJ 비비고 순살 구이(삼치/고등어, 상품별 상이) 2개 이상 구매시 1개당 각4,192원 
    ※1개 구매시 각5,240원 ※교차구매 가능

    2개 이상 25% 
    하남돼지집 매콤 콩나물 불고기 外 3종(상품별 상이) 2개 이상 구매시 1개당 각7,425~11,175원
    ※1개 구매시 각9,900~14,900원 ※교차구매 가능

    2개 이상 50%
    풀무원 노엣지 피자 3종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,990원 ※1개 구매시 각9,980원
    ※교차구매 가능 ※코리안BBQ/포테이토&콘/토마토&미트콤보

    2개 이상 각1,000원 할인
    CJ 고메 소바바 치킨 5종(양념순살 外 4종, 상품별 규격 상이) 2개 이상 구매시 1개당 각7,980원
    ※1개 구매시 각8,980원 ※교차구매 가능

    2개 이상 20% 
    오뚜기 마열라면(4개입) 2개 이상 구매시 1개당 각3,584원 ※1개 구매시 4,480원

    2개 이상 40% 
    씨제이 햇반 솥반 5종(상품별 규격 상이) 2개 이상 구매시 1개당 각2,988원 
    ※1개 구매시 각4,980원 ※교차구매 가능

    2개 이상 50% 
    샘표 고추장/된장/쌈장 전품목 15종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,245~16,490원
    ※1개 구매시 각2,490~32,980원
    ※교차구매 가능 ※점별 운영상품 상이

    2개 이상 50% 
    남양 불가리스 제로 3종(각 80g×4입) 2개 이상 구매시 1개당 각1,890원 
    ※1개 구매시 각3,780원 ※교차구매 가능 ※딸기/복숭아/플레인

    2개 이상 50% 
    일동후디스 그릭요거트 3종(각 80g×4입) 2개 이상 구매시 1개당 각3,200원 
    ※1개 구매시 각6,400원 ※교차구매 가능 ※플레인/저지방/코코포도

    2개 이상 10% 
    크라운 스낵 5종(상품별 상이) 2개 이상 구매시 1개당 각1,782원 ※1개 구매시 각1,980원 ※교차구매 가능

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    발렌타인데이 

    해당상품 롯데/신한/NH농협/삼성카드 1만 8천원 이상 결제시 5,000원 롯데상품권 증정
    ※해당 브랜드(8개사) : 롯데웰푸드/매일유업/농심/한국마즈/해태제과/크라운제과/오리온/삼경(하리보 제외)
    ※페이지 하단 카드할인 세부내용 참고 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " V "를 확인하세요 ※결제시 계산대에서 수령

    10%
    매일 페레로로쉐 T8 하트(100g)/T24 다이아(300g) 8,820/22,590원

    롯데 프리미엄 가나 레몬 치즈 타르트/베리무스케익(각 68g) 각3,490원

    하리보 스위트박스(600g) 9,980원 ※상품권 증정 제외

    부샤드 나폴리탄 카라멜&씨솔트 초콜릿 틴 기획(180g) 15,980원 ※상품권 증정 제외

    목우촌 프라임 소시지 2종(상품별 상이) 각6,980원 ※기간 : 2/1(토)~2/28(금)

    풀무원 얇은피 꽉찬속 김치/고기만두(각 800g) 각8,980원

    사조대림 실속김밥 세트(510g) 7,980원 ※기간 : 2/1(토)~2/28(금)

    3,000원 할인
    사세 치킨 가라아게(500g) 6,980원

    2,500원 할인
    풀무원 체다모짜 핫도그(320g)/탱글뽀득 핫도그(400g) 각6,480원

    L.POINT 45%
    풀무원 식물성 지구식단 로스팅 정면(4개입) 2,998원

    L.POINT 6,970원 
    백설 고소함 가득 참기름(500ml) 7,980원

    서울 흰우유(2.3L) 6,280원

    각700원 할인
    서울 비요뜨 4종(상품별 상이) 각2,180원
    ※링크/초코링/크런치볼/쿠키앤크림
    
    아임비타 해당상품 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ■ "를 확인하세요
    ※결제시 계산대에서 수령

    2+1 아임비타 이뮨플러스(23.5g×7입) 16,900원

    2개 이상 34% 락토핏 골드/코어/슬림(상품별 상이) 2개 이상 구매시 1개당 각10,494~17,754원 
    ※1개 구매시 각15,900~26,900원 ※교차구매 가능

    남양 드빈치 자연방목 피자치즈(1kg) 13,980원

    소와나무 이태리안 피자치즈(280g) 6,280원

    각600원 할인
    아침에주스 오렌지/포도/사과(각 1.8L) 각5,980원

    인기 생필품 득템 찬스

    1+1
    히말라야 핑크솔트 바디워시 3종(각 900g) 각12,980원
    ※민트/로즈/포레스트 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    엘라스틴 아미노 프로틴 클리닉 샴푸/컨디셔너(각 855ml) 2개 이상 구매시 1개당 각6,450원 
    ※1개 구매시 각12,900원 ※샴푸 : 손상/볼륨 ※컨디셔너 : 손상
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 각2,500원 할인
    닥터그루트 집중케어 샴푸 기획(각 샴푸 400ml+50ml+컨디셔너 50ml) 2개 이상 구매시 1개당 각20,500원
    ※1개 구매시 각23,000원 ※힘없는 모발용/가려운 두피용 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    갸스비 왁스 4종&스프레이 3종(상품별 상이) 2개 이상 구매시 1개당 각4,545~6,000원 
    ※1개 구매시 각9,090~12,000원 ※점별 운영상품 상이 ※교차구매 가능

    1+1
    센소다인 딥클린 칫솔/젠틀칫솔(각 4입) 각10,900원 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 55%
    해피바스 클렌징 폼 3종(각 200g) 2개 이상 구매시 1개당 각4,005원 ※1개 구매시 각8,900원
    ※어성초/라이스/대나무숯 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    2080 파워쉴드 플러스 치약(블루)(120g×3입) 2개 이상 구매시 1개당 각4,950원 
    ※1개 구매시 9,900원

    2개 이상 50%
    잘풀리는집 키친타월(150매×4입) 2개 이상 구매시 1개당 각2,950원 ※1개 구매시 5,900원

    카밀 핸드크림 미니기획(30ml×3입) 6,900원 ※점별 재고 상이

    우르오스 스칼프 샴푸(500ml)/스킨 밀크, 스킨로션(각 200ml) 각23,500원 ※점별 운영상품 상이

    2개 이상 50%
    테크/피지 액체세제 11종(상품별 상이) 2개 이상 구매시 1개당 각7,100~14,950원
    ※1개 구매시 각14,200~29,900원
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    프릴 애플 주방세제(용기 700ml/리필 1L) 2개 이상 구매시 1개당 각4,750원 
    ※1개 구매시 각9,500원 ※교차구매 가능

    2개 이상 50%
    홈스타 맥스프레쉬 4종(각 900ml) 2개 이상 구매시 1개당 각3,950원 
    ※1개 구매시 각7,900원 ※교차구매 가능

    피지오겔 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " B "를 확인하세요
    ※결제시 계산대에서 수령

    피지오겔 DMT 로션(200ml)/크림(150ml) 21,510/26,910원 ※점별 운영상품 상이

    쏘피 생리대 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    쏘피 한결 생리대(팬티라이너/중형, 대형, 슈퍼롱) 2개 이상 구매시 1개당 각4,250/5,950원 
    ※1개 구매시 각8,500/11,900원 ※교차구매 가능

    헨켈 라벤더 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉭ "를 확인하세요
    ※결제시 계산대에서 수령

    최대50%
    헨켈(퍼실/버넬/브레프)라벤더 대전(상품별 상이) 각2,250~19,900원

    하기스 기저귀 전품목 10만원 이상 구매시 10,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉻ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    하기스 기저귀(상품별 상이) 2개 이상 구매시 1개당 각15,300~22,950원 
    ※1개 구매시 각30,600~45,900원 ※교차구매 가능

    피죤 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉬ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    피죤(액츠/피죤/무균무때)브랜드 대전(상품별 상이) 2개 이상 구매시 1개당 각3,450~9,950원 
    ※1개 구매시 각6,900~19,900원 ※브랜드별 교차구매 가능

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
  국민맥주 3종 6캔 구매시 10% 할인

    6캔 구매시 10%
    국민맥주 3종(각 500ml, 라거/에일/바이젠) 6캔 구매시 1캔당 각1,620~1,800원 
    ※1캔 구매시 각1,800~2,000

    별빛 청하 변온잔 패키지(295ml×4) 7,960원

    L.POINT 40%
    해피콜 뉴골든구르메 IH 프라이팬/냄비(상품별 상이) 20,940~43,140원

    브랜드 IH 프라이팬 3종(각 28cm) 각20,900원
    ※테팔/해피콜/도루코 인덕션 프라이팬 3종
    ※기간 : 1/31(금)~2/26(수)

    최대 40%
    레녹스/프라카 식기(상품별 상이) 각4,830~21,540원 

    1+1 기획팩
    유니랩 위생롤백/장갑/지퍼백(상품별 상이) 각3,480~5,980원

    1+1 기획팩
    3M 황토숯 고무장갑/다목적 수세미(소 3입) 각3,190~4,380원

    20%
    썬 부탄가스(4입) 5,430원

    2개 이상 50%
    네오플램 퓨어락(상품별 상이) 2개 이상 구매시 1개당 각2,200~6,200원
    ※1개 구매시 각4,400~12,400원 ※교차구매 가능

    오늘좋은 냉동밥 용기(8P) 4,900원

    20%
    오늘좋은 하트쿠션&과자 바디필로우(상품별 상이) 7,920~23,920원
    ※점별 재고 상이 ※해당상품에 한함
    ※재고 소진시 조기 종료

    20%
    사각사각 호텔식 워싱차렵이불/오늘좋은 호텔베개(상품별 상이) 23,920~37,520원
    ※이불 - S : 150×200cm/Q : 180×210cm
    ※베개 - 40×60cm/50×70cm ※점별 재고 상이
    ※해당상품에 한함 ※재고 소진시 조기 종료

    30%
    오늘좋은 아로마 세면타월(각 4입) 각13,930원
    ※아쿠아/블라썸/유칼립투스/라벤더
    ※입점점에 한함 ※해당상품에 한함

    아쿠아듀오 블라썸 필터샤워 세트(염소제거용)(헤드+리필 2입) 각15,900원 ※버터/그레이
    ※입점점에 한함 ※점별 입점상품 상이 ※해당상품에 한함

    20%
    오늘좋은 포켓형 장바구니 2종 2,000원

    최대 30%
    자동차용품 클리어런스(상품별 상이) 각1,840~66,320원

    20% 코알라하트호야/코코도르 에프터눈티 디퓨저 7,920/9,520원

    방한 패션잡화클리어런스(장갑/모자/가방/머플러/넥워머 등) 각2,000~13,000원

    2개 이상 50%
    하우홈 보온/단열시트 9종 2개 이상 구매시 1개당 각990~8,450원 ※1개 구매시 각1,990~16,900원 ※교차구매 가능

    2개 이상 30%
    브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각7,920~8,720원 
    ※1개 구매시 각6,930~7,630원 ※교차구매 가능
    ※뉴발란스, 언더아머, 휠라, 네파, MLB 등 ※점별 상품 상이

    라임/TRY 브랜드 잠옷(상품별 상이) 각11,940~26,940원 ※기간 : 1/23(목)~2/5(수)

    오늘좋은 겨울 침구/담요/거실화 外(상품별 상이) 각3,000~35,000원
    ※입점점에 한함 ※해당상품에 한함
    ※최초 판매가 기준 ※소진시 종료 ※점별 재고 상이
    ※매장에 더많은 상품이 준비되어 있습니다

    오늘좋은 히트 언더웨어(1입) 각5,900~9,900원

    오늘좋은 수면/밍크스판 홈웨어(바지/원피스/세트) 각6,900~12,900원

    산리오 外 아동 캐릭터 실내복(상품별 상이) 각6,900~21,900원

    ToysRus 디즈니 캐릭터 눈집게 3,900원

    2,400원 할인 디즈니 눈집게 3종(34.3×10.6×8.4cm) 3,900원

    colioli 반려동물 인기 식/용품 20%

    20%
    콜리올리 도톰한 3~4회용 패드 3종(M 80매/L 30매/XL 20매) 각11,120~16,720원

    20%
    콜리올리 스크래쳐 4종(상품별 상이) 각3,600~12,720원

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
];

const zettaSeoulData = [
  `<h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2025. 1. 31(금) ~ 2025. 2. 12(수) < 신선식품 적용기간 : 2025. 1. 31(금) ~ 2. 5(수) >
    ·제타플렉스 서울역점

    롯데마트 맛 좋은 신선과 간편식 추천!

    명절 끝! 집밥 솔루션

    한우 구이류(1등급)(각 100g/냉장/국내산 한우고기) L.POINT 30% 
    ※조기 품절될 수 있습니다

    딸기 전품목(상품별 상이/국산) 2개 이상 구매시 각2,000원 할인 
    ※교차구매 가능 ※점별 운영상품 상이

    L.POINT 35%
    남해안 생굴(100g/냉장/국산) 1,918원

    큰초밥(20입)(팩/원산지 별도표기) 15,990원

    숯불 무뼈 닭발(팩/원산지 별도표기) 11,900원
    ※족발매장 입점점에 한함 

    2개 이상 10%
    청정원 호밍스 냉동 밀키트 8종(상품별 규격 상이) 2개 이상 구매시 1개당 각9,900~13,950원
    ※1개 구매시 각 11,000~15,500원
    ※부산식 곱창전골 外 7종 ※교차구매 가능

    2개 이상 각1,980원 할인
    풀무원 생 수제비/우동 5종(상품별 상이) 2개 이상 구매시 1개당 각5,000원 
    ※1개 구매시 각 6,980원 ※교차구매 가능

    3개 이상 각 1,490원 할인
    오뚜기 맛있는 떡볶이 3종(상품별 상이) 3개 이상 구매시 1개당 각3,300원 
    ※1개 구매시 각 4,790원 ※교차구매 가능

    1+1 
    동원 바른어묵 전골용(350g) 4,480원

    최대 50%
    제로 대용량 탄산음료 15종(상품별 행사 상이/1.2L~1.8L) 1,290~2,740원

    세계맥주 골라담기(상품별 상이) 5캔 구매시 12,900원
    ※4캔 구매시 9,600원
    ※1캔 구매시 3,000원

    롯데마트 GO앱 특가!
    ▶기간 : 1/31(금) ~ 2/5(수)
    ※롯데마트GO앱 스캔시 행사가 적용됩니다

    GO앱 스캔시 20%
    프리미엄 장어초밥(8입/냉장/원산지 별도표기) 9,920원

    GO앱 스캔시 6,000원 할인
    동원 프리미엄 명란 기획(100g×2/냉장/원산지 별도표기) 10,900원

    GO앱 스캔시 10,000원 할인
    아임비타 마그네슘샷(25ml×10병) 19,900원

    2025 롯데마트 물가잡기 캠페인 매주 꼭 필요한 상품을 THE HOT하게 제안합니다.

    이번주 핫프라이스

    스테비아 대추방울토마토 전품목(500g/팩/국산) 2팩 이상 구매시 각2,000원 할인 
    ※교차구매 가능
    ※점별 운영상품 상이
    ※조기 품절될 수 있습니다

    롯데/신한/NH농협/삼성카드 1,000원 할인
    대파(700g/봉/국산) 1,990원
    ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/NH농협/삼성카드 2개 이상 55%
    크리넥스 순수소프트 3겹 화장지(35m×30롤) 해당카드로 2개 이상 구매시 1개당 각17,500원
    ※1개 구매시 38,900원
    ※기간 : 1/31(금) ~ 2/5(수) ※페이지 하단 카드할인 세부내용 참고

    이번주 핫PB

    요리하다 소고기라면(5개입) 2,990원

    1,000원 할인
    요리하다 얼큰한 김치우동 2인(432g) 2,990원

    1,000원 할인
    요리하다 칠리새우(400g) 6,990원

    3개 이상 20%, 2개 이상 10%
    오늘좋은 밀크/다크 초콜릿(각 100g) 3개 이상 구매시 1개당 각1,600원 
    ※2개 구매시 1개당 각 1,800원
    ※1개 구매시 각 2,000원 ※교차구매 가능

    공구핫딜

    공구핫딜 한성 와일드 크래미(180g×3입) 6,980원
    
    칠성사이다(190ml×30입) 14,490원

    롯데칠성 델몬트 팜앤홈 제주감귤(1.2L×4입) 5,880원

    오늘좋은 공구핫딜 베이직 거실화 2P(그레이/네이비) 각9,900원

    ※정상가란? 2025년 1월 10일(금) ~ 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    미국산/호주산 냉동 간편구이 모음(품목별 상이/냉동/원산지 별도표기) L.POINT 최대 40%

    국내산 돼지고기 삼겹살/목심(각 100g/냉장/국내산) 각2,390원
    ※제주점 제외
    ※조기 품절될 수 있습니다

    L.POINT 50%
    요리하다 흑돼지 제육볶음(800g/냉장/원산지 별도표기) 9,900원

    L.POINT 50%
    달구벌 소대창/소곱창(소금구이)(400g/300g, 냉동, 원산지 별도표기) 각8,950원

    1+1
    노랑오리 마늘치킨 훈제슬라이스(400g/냉장/원산지 별도표기) 9,990원

    L.POINT 40%
    ASC 냉동 새우살 4종(400g/냉동/원산지 별도표기) 13,140원

    L.POINT 10%
    손질 오징어(3마리/해동/원양산) 9,990원

    L.POINT 2,000원
    천년 목사골 홍어(모둠)(150g/냉장/아르헨티나산) 9,900원

    동원 양반 들기름향이 그윽한 식탁김(4.5g×20봉/원산지 별도표기) 7,980원

    풀무원 동물복지 구운란(20입/원산지 별도표기) 9,990원

    샐러리(봉/국산)/비트(1kg/봉/국산)/케일(봉/국산) 각3,990원

    데일리 샐러드(300g/국산) 2,990원

    2봉 이상 각 2,000원 할인
    제주 한라봉/천혜향/레드향(각 4~7입/봉/국산) 2봉 이상 구매시 1봉당 각11,990원 ※1봉 구매시 각13,990원

    2팩 이상 각 2,000원 할인
    칠레산 블루베리(310g/팩/칠레산) 2팩 이상 구매시 1팩당 각5,990원 ※1팩 구매시 7,990원

    스위티오/감숙왕 바나나(1.3kg 내외/송이/필리핀산) 3,990원

    L.POINT 35% 
    갱엿 닭강정(대)(1팩/원산지 별도표기) 11,044원

    한달내내 +50% 중량 UP "가격은 그대로"
    온가족 한통가득 탕수육(1팩/원산지 별도표기) 12,990원

    김밥&유부&롤 한판세트(1팩/원산지 별도표기) 10,990원

    L.POINT 20%
    바비큐 대왕통다리(1팩/원산지 별도표기) 7,992원 

    2,000원 할인
    한겹씩 뜯어먹는 티슈브레드 2입(1봉/원산지 별도표기) 7,900원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    하나사면 하나 더 1+1

    1+1
    동원 가쓰오 크랩스(144g) 3,980원

    1+1
    CJ 비비고 떡볶이 3종(각 360g, 오리지널/매운/치즈) 각5,480원
    ※교차구매 가능

    1+1
    CJ 비비고 곱창전골(800g)/곱창순대전골(935g) 15,980/17,980원
    ※기간 : 1/31(금)~2/2(일) ※동일 상품에 한함

    1+1
    종가 맛김치(800g) 14,900원

    1+1
    하림 더미식 교자 2종(각 350g×2입)/춘천 닭갈비 볶음밥(210g×2입) 7,980/10,980원
    ※기간 : 1/31(금)~2/2(일) ※동일 가격 간 교차구매 가능

    1+1
    롯데쉐푸드 미니돈까스(380g) 6,980원

    1+1
    씨제이 햇반 곤약밥 2종(각 150g×3입, 귀리흑미/현미귀리) 각8,980원
    ※교차구매 가능

    1+1
    다담 파우치양념 5종(상품별 규격 상이) 각2,980원 ※교차구매 가능

    1+1
    해찬들 원조 태양초 고추장(1.5kg) 19,000원

    1+1
    빙그레 끼리 크림치즈 그릭스타일(200g) 8,000원

    1+1
    파워에이드 1.5L 外 스포츠음료 8종(상품별 상이) 1,980~3,980원 ※동일 브랜드간 교차구매가능

    1+1
    달고나 쿠키/허쉬 초콜릿칩쿠키/허쉬 초콜릿칩 베리쿠키(각 144g) 각5,290원 ※교차구매 가능

    L.POINT 1+1
    삼립 상쾌한아침 우유식빵/삼립 미니 꿀호떡 2종(상품별 규격 상이) 각3,480원
    ※오리지널/옥수수※교차구매 가능

    1+1
    베지밀 검은콩 고칼슘두유(파우치)(190ml×15입) 18,750원

    1+1
    대용량 과채주스 27종(1L~1.8L) 3,630~14,900원 ※동일 브랜드 내 동일가격 간 교차구매가능

    1+1
    녹차원 꿀 유자차 外 5종(각 1kg) 각9,900원 ※교차구매 가능

    1+1
    녹차원 생강차/쌍화차(각 15g×30입) 각9,900원 ※교차구매 가능

    2개 이상 구매시 더 싸게

    2개 이상 최대4,480원 할인
    CJ 육공육 4종(상품별 상이) 2개 이상 구매시 1개당 각5,000원 
    ※1개 구매시 각5,580~9,480원 ※교차구매 가능

    2개 이상 20% 
    CJ 비비고 순살 구이(삼치/고등어, 상품별 상이) 2개 이상 구매시 1개당 각4,192원 
    ※1개 구매시 각5,240원 ※교차구매 가능

    2개 이상 25% 
    하남돼지집 매콤 콩나물 불고기 外 3종(상품별 상이) 2개 이상 구매시 1개당 각7,425~11,175원
    ※1개 구매시 각9,900~14,900원 ※교차구매 가능

    2개 이상 50%
    풀무원 노엣지 피자 3종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,990원 ※1개 구매시 각9,980원
    ※교차구매 가능 ※코리안BBQ/포테이토&콘/토마토&미트콤보

    2개 이상 각1,000원 할인
    CJ 고메 소바바 치킨 5종(양념순살 外 4종, 상품별 규격 상이) 2개 이상 구매시 1개당 각7,980원
    ※1개 구매시 각8,980원 ※교차구매 가능

    2개 이상 20% 
    오뚜기 마열라면(4개입) 2개 이상 구매시 1개당 각3,584원 ※1개 구매시 4,480원

    2개 이상 40% 
    씨제이 햇반 솥반 5종(상품별 규격 상이) 2개 이상 구매시 1개당 각2,988원 
    ※1개 구매시 각4,980원 ※교차구매 가능

    2개 이상 50% 
    샘표 고추장/된장/쌈장 전품목 15종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,245~16,490원
    ※1개 구매시 각2,490~32,980원
    ※교차구매 가능 ※점별 운영상품 상이

    2개 이상 50% 
    남양 불가리스 제로 3종(각 80g×4입) 2개 이상 구매시 1개당 각1,890원 
    ※1개 구매시 각3,780원 ※교차구매 가능 ※딸기/복숭아/플레인

    2개 이상 50% 
    일동후디스 그릭요거트 3종(각 80g×4입) 2개 이상 구매시 1개당 각3,200원 
    ※1개 구매시 각6,400원 ※교차구매 가능 ※플레인/저지방/코코포도

    2개 이상 10% 
    크라운 스낵 5종(상품별 상이) 2개 이상 구매시 1개당 각1,782원 ※1개 구매시 각1,980원 ※교차구매 가능

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    발렌타인데이 

    해당상품 롯데/신한/NH농협/삼성카드 1만 8천원 이상 결제시 5,000원 롯데상품권 증정
    ※해당 브랜드(8개사) : 롯데웰푸드/매일유업/농심/한국마즈/해태제과/크라운제과/오리온/삼경(하리보 제외)
    ※페이지 하단 카드할인 세부내용 참고 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " V "를 확인하세요 ※결제시 계산대에서 수령

    10%
    매일 페레로로쉐 T8 하트(100g)/T24 다이아(300g) 8,820/22,590원

    롯데 프리미엄 가나 레몬 치즈 타르트/베리무스케익(각 68g) 각3,490원

    하리보 스위트박스(600g) 9,980원 ※상품권 증정 제외

    부샤드 나폴리탄 카라멜&씨솔트 초콜릿 틴 기획(180g) 15,980원 ※상품권 증정 제외

    목우촌 프라임 소시지 2종(상품별 상이) 각6,980원 ※기간 : 2/1(토)~2/28(금)

    풀무원 얇은피 꽉찬속 김치/고기만두(각 800g) 각8,980원

    사조대림 실속김밥 세트(510g) 7,980원 ※기간 : 2/1(토)~2/28(금)

    3,000원 할인
    사세 치킨 가라아게(500g) 6,980원

    2,500원 할인
    풀무원 체다모짜 핫도그(320g)/탱글뽀득 핫도그(400g) 각6,480원

    L.POINT 45%
    풀무원 식물성 지구식단 로스팅 정면(4개입) 2,998원

    L.POINT 6,970원 
    백설 고소함 가득 참기름(500ml) 7,980원

    서울 흰우유(2.3L) 6,280원

    각700원 할인
    서울 비요뜨 4종(상품별 상이) 각2,180원
    ※링크/초코링/크런치볼/쿠키앤크림
    
    아임비타 해당상품 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ■ "를 확인하세요
    ※결제시 계산대에서 수령

    2+1 아임비타 이뮨플러스(23.5g×7입) 16,900원

    2개 이상 34% 락토핏 골드/코어/슬림(상품별 상이) 2개 이상 구매시 1개당 각10,494~17,754원 
    ※1개 구매시 각15,900~26,900원 ※교차구매 가능

    남양 드빈치 자연방목 피자치즈(1kg) 13,980원

    소와나무 이태리안 피자치즈(280g) 6,280원

    각600원 할인
    아침에주스 오렌지/포도/사과(각 1.8L) 각5,980원

    인기 생필품 득템 찬스

    1+1
    히말라야 핑크솔트 바디워시 3종(각 900g) 각12,980원
    ※민트/로즈/포레스트 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    엘라스틴 아미노 프로틴 클리닉 샴푸/컨디셔너(각 855ml) 2개 이상 구매시 1개당 각6,450원 
    ※1개 구매시 각12,900원 ※샴푸 : 손상/볼륨 ※컨디셔너 : 손상
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 각2,500원 할인
    닥터그루트 집중케어 샴푸 기획(각 샴푸 400ml+50ml+컨디셔너 50ml) 2개 이상 구매시 1개당 각20,500원
    ※1개 구매시 각23,000원 ※힘없는 모발용/가려운 두피용 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    갸스비 왁스 4종&스프레이 3종(상품별 상이) 2개 이상 구매시 1개당 각4,545~6,000원 
    ※1개 구매시 각9,090~12,000원 ※점별 운영상품 상이 ※교차구매 가능

    1+1
    센소다인 딥클린 칫솔/젠틀칫솔(각 4입) 각10,900원 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 55%
    해피바스 클렌징 폼 3종(각 200g) 2개 이상 구매시 1개당 각4,005원 ※1개 구매시 각8,900원
    ※어성초/라이스/대나무숯 ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    2080 파워쉴드 플러스 치약(블루)(120g×3입) 2개 이상 구매시 1개당 각4,950원 
    ※1개 구매시 9,900원

    2개 이상 50%
    잘풀리는집 키친타월(150매×4입) 2개 이상 구매시 1개당 각2,950원 ※1개 구매시 5,900원

    카밀 핸드크림 미니기획(30ml×3입) 6,900원 ※점별 재고 상이

    우르오스 스칼프 샴푸(500ml)/스킨 밀크, 스킨로션(각 200ml) 각23,500원 ※점별 운영상품 상이

    2개 이상 50%
    테크/피지 액체세제 11종(상품별 상이) 2개 이상 구매시 1개당 각7,100~14,950원
    ※1개 구매시 각14,200~29,900원
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    프릴 애플 주방세제(용기 700ml/리필 1L) 2개 이상 구매시 1개당 각4,750원 
    ※1개 구매시 각9,500원 ※교차구매 가능

    2개 이상 50%
    홈스타 맥스프레쉬 4종(각 900ml) 2개 이상 구매시 1개당 각3,950원 
    ※1개 구매시 각7,900원 ※교차구매 가능

    피지오겔 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " B "를 확인하세요
    ※결제시 계산대에서 수령

    피지오겔 DMT 로션(200ml)/크림(150ml) 21,510/26,910원 ※점별 운영상품 상이

    쏘피 생리대 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    쏘피 한결 생리대(팬티라이너/중형, 대형, 슈퍼롱) 2개 이상 구매시 1개당 각4,250/5,950원 
    ※1개 구매시 각8,500/11,900원 ※교차구매 가능

    헨켈 라벤더 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉭ "를 확인하세요
    ※결제시 계산대에서 수령

    최대50%
    헨켈(퍼실/버넬/브레프)라벤더 대전(상품별 상이) 각2,250~19,900원

    하기스 기저귀 전품목 10만원 이상 구매시 10,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉻ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    하기스 기저귀(상품별 상이) 2개 이상 구매시 1개당 각15,300~22,950원 
    ※1개 구매시 각30,600~45,900원 ※교차구매 가능

    피죤 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉬ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    피죤(액츠/피죤/무균무때)브랜드 대전(상품별 상이) 2개 이상 구매시 1개당 각3,450~9,950원 
    ※1개 구매시 각6,900~19,900원 ※브랜드별 교차구매 가능

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
  `<div>
    국민맥주 3종 6캔 구매시 10% 할인

    6캔 구매시 10%
    국민맥주 3종(각 500ml, 라거/에일/바이젠) 6캔 구매시 1캔당 각1,620~1,800원 
    ※1캔 구매시 각1,800~2,000

    별빛 청하 변온잔 패키지(295ml×4) 7,960원

    L.POINT 40%
    해피콜 뉴골든구르메 IH 프라이팬/냄비(상품별 상이) 20,940~43,140원

    브랜드 IH 프라이팬 3종(각 28cm) 각20,900원
    ※테팔/해피콜/도루코 인덕션 프라이팬 3종
    ※기간 : 1/31(금)~2/26(수)

    최대 40%
    레녹스/프라카 식기(상품별 상이) 각4,830~21,540원 

    1+1 기획팩
    유니랩 위생롤백/장갑/지퍼백(상품별 상이) 각3,480~5,980원

    1+1 기획팩
    3M 황토숯 고무장갑/다목적 수세미(소 3입) 각3,190~4,380원

    20%
    썬 부탄가스(4입) 5,430원

    2개 이상 50%
    네오플램 퓨어락(상품별 상이) 2개 이상 구매시 1개당 각2,200~6,200원
    ※1개 구매시 각4,400~12,400원 ※교차구매 가능

    오늘좋은 냉동밥 용기(8P) 4,900원

    20%
    오늘좋은 하트쿠션&과자 바디필로우(상품별 상이) 7,920~23,920원
    ※점별 재고 상이 ※해당상품에 한함
    ※재고 소진시 조기 종료

    20%
    사각사각 호텔식 워싱차렵이불/오늘좋은 호텔베개(상품별 상이) 23,920~37,520원
    ※이불 - S : 150×200cm/Q : 180×210cm
    ※베개 - 40×60cm/50×70cm ※점별 재고 상이
    ※해당상품에 한함 ※재고 소진시 조기 종료

    30%
    오늘좋은 아로마 세면타월(각 4입) 각13,930원
    ※아쿠아/블라썸/유칼립투스/라벤더
    ※입점점에 한함 ※해당상품에 한함

    아쿠아듀오 블라썸 필터샤워 세트(염소제거용)(헤드+리필 2입) 각15,900원 ※버터/그레이
    ※입점점에 한함 ※점별 입점상품 상이 ※해당상품에 한함

    20%
    오늘좋은 포켓형 장바구니 2종 2,000원

    최대 30%
    자동차용품 클리어런스(상품별 상이) 각1,840~66,320원

    20% 코알라하트호야/코코도르 에프터눈티 디퓨저 7,920/9,520원

    방한 패션잡화클리어런스(장갑/모자/가방/머플러/넥워머 등) 각2,000~13,000원

    2개 이상 50%
    하우홈 보온/단열시트 9종 2개 이상 구매시 1개당 각990~8,450원 ※1개 구매시 각1,990~16,900원 ※교차구매 가능

    2개 이상 30%
    브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각7,920~8,720원 
    ※1개 구매시 각6,930~7,630원 ※교차구매 가능
    ※뉴발란스, 언더아머, 휠라, 네파, MLB 등 ※점별 상품 상이

    라임/TRY 브랜드 잠옷(상품별 상이) 각11,940~26,940원 ※기간 : 1/23(목)~2/5(수)

    오늘좋은 겨울 침구/담요/거실화 外(상품별 상이) 각3,000~35,000원
    ※입점점에 한함 ※해당상품에 한함
    ※최초 판매가 기준 ※소진시 종료 ※점별 재고 상이
    ※매장에 더많은 상품이 준비되어 있습니다

    오늘좋은 히트 언더웨어(1입) 각5,900~9,900원

    오늘좋은 수면/밍크스판 홈웨어(바지/원피스/세트) 각6,900~12,900원

    산리오 外 아동 캐릭터 실내복(상품별 상이) 각6,900~21,900원

    ToysRus 디즈니 캐릭터 눈집게 3,900원

    2,400원 할인 디즈니 눈집게 3종(34.3×10.6×8.4cm) 3,900원

    colioli 반려동물 인기 식/용품 20%

    20%
    콜리올리 도톰한 3~4회용 패드 3종(M 80매/L 30매/XL 20매) 각11,120~16,720원

    20%
    콜리올리 스크래쳐 4종(상품별 상이) 각3,600~12,720원

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>`,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202502_1";
const category = [];

const data = {
  L101: {
    title: "스마트전단지-전점101",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L103: {
    title: "스마트전단지-제타플렉스 잠실점103",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L105: {
    title: "스마트전단지-제타플렉스 서울역점105",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
