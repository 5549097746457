//링크
export const LEAFLET_URL = {
  a1: "https://www.lotteon.com/p/display/shop/seltDpShop/12919?mall_no=4",
  a2: "http://toysrus.lottemart.com",
  chu2020: "https://www.lotteon.com/p/display/shop/seltDpShop/29638",
  main: "http://www.lottemart.com",
};

export const IMAGE_PATH = `${process.env.REACT_APP_BASE_S3_IMAGE}/leaflet`;

/* 지난 전단지 reload */
export const leafletState = {
  // openDate는 오픈날짜로 변경(매주 목요일)
  // 전점,제타플렉스,맥스 위치 고정
  MART: {
    openDate: process.env.REACT_APP_TYPE === "DEV" ? "20250123" : "20250131",
    thisWeek: [
      501, //전점 (~1/30) **위치 고정
      503, //제타플렉스 (~1/30) **위치 고정
      748, //맥스 (~1/22) **위치 고정
      505, //제타플렉스 서울역점 (~1/30)

      521, //당진점(~1/30)
      531, //천호점(~1/30)
      905, //전점 설 본 세트(~1/29)
      791, //맥스 설 본 세트(~1/29)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
    willThisWeek: [
      101, //전점 (~2/5) **위치 고정
      103, //제타플렉스 (~2/5) **위치 고정
      718, //맥스 (~1/12) **위치 고정
      105, //제타플렉스 서울역점 (~2/5)

      121, //당진점(~2/5)
      131, //천호점(~2/5)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
  },
  // 롯데슈퍼 대표 전단
  // 성수연 담당 요청 (24.03.28) 슈퍼 LMS 추가
  SUPER: {
    openDate: process.env.REACT_APP_TYPE === "DEV" ? "20250123" : "20250131",
    thisWeek: [
      509, //슈퍼(~1/30)
      809, //슈퍼 설 본세트 (~1/29)
    ],
    willThisWeek: [
      109, //슈퍼(~2/5)
    ],
  },
  // 롯데슈퍼 추가 전단(설,명절 등)
  /*SUPER_THEME: {
    openDate: "20230831",
    thisWeek: [505],
    willThisWeek: [105],
  },*/
};
